<template>
  <dialog-base
    :key="renderKey"
    v-bind="$props"
    header="Opendock NOVA User Feedback"
    loading-message="Submitting Feedback..."
    @close="close"
    @confirm="$refs.feedbackForm.submit()">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button
        x-small
        before-icon="chat"
        v-on="slotProps.on"
        v-bind="[slotProps.attrs, $props, $attrs]"
        >Feedback</primary-button
      >
    </template>
    <template slot="body">
      <feedback-form @close="close" ref="feedbackForm"></feedback-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for feedback form
 * @displayName Feedback Dialog
 */
export default {
  mixins: [dialogMixin]
};
</script>
