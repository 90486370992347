<template>
  <div class="text-center">
    <v-stepper
      v-model="currentStep"
      :alt-labels="true"
      :flat="true"
      class="status-stepper"
      :class="{ 'mb-8': !dense, compact }">
      <v-stepper-header v-if="!compact">
        <template v-for="(status, index) in statuses">
          <v-divider
            class="divider"
            :key="`divider-${index}`"
            v-show="index && status.show"></v-divider>
          <v-stepper-step
            v-show="status.show"
            :step="status.stepNumber"
            :class="{
              dense: dense,
              'is-requested': novaCore.isRequested(appointment) && status.name === requestedStatus,
              'is-inprogress':
                novaCore.isInProgress(appointment) && status.name === inProgressStatus
            }"
            :complete-icon="getCompletedIcon(status)"
            :complete="status.stepNumber <= currentStep || currentStep === 0"
            :color="status.name.toLowerCase()"
            :alt-labels="true"
            :key="`status-${index}`">
            <div class="text-center">
              <div class="font-weight-medium status-name">
                {{ novaCore.breakWordsAtCaps(status.name) }}
              </div>

              <template v-if="displayTime(status)">
                <div class="d-flex flex-wrap-nowrap align-center is-relative">
                  <div
                    class="font-size-x-small full-width"
                    v-if="appointment.statusTimeline[status.name]">
                    {{
                      formatDateTime(
                        appointment.statusTimeline[status.name],
                        novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
                        novaCore.LuxonDateTimeFormats.MonthDayYearSlashedTime24
                      )
                    }}
                  </div>
                  <v-btn
                    small
                    icon
                    @click="openEditor(status.name)"
                    v-if="canEdit(status)"
                    class="time-edit-button">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </div>
                <div class="caption text-uppercase" v-html="timeDiffInfo(status.name)"></div>

                <template v-if="hasCheckinStatus">
                  <div
                    class="mt-1 pa-1 pill text-uppercase black--text bg--color-neutral-20 text--color-neutral-90 font-size-xx-small font-weight-medium"
                    v-if="status.name === arrivedStatus && appointment.isCheckedInByCarrier">
                    <span class="pt-1">Checked in by carrier</span>
                  </div>
                  <span
                    class="pa-1 pill text-uppercase bg--color-neutral-20 text--color-neutral-90 font-size-xx-small font-weight-medium"
                    v-if="status.name === arrivedStatus && isUnplannedAppointment">
                    <span>Unplanned</span>
                  </span>

                  <span
                    class="pa-1 px-2 pill text-uppercase bg--color-neutral-20 text--color-neutral-90 font-size-xx-small font-weight-medium"
                    v-if="status.name === completedStatus && isAssetDeparted">
                    <span>Checked Out</span>
                  </span>
                </template>
              </template>
            </div>
          </v-stepper-step>
        </template>
      </v-stepper-header>

      <eta-button-group
        v-if="appointment.status === novaCore.AppointmentStatus.Scheduled && !compact"
        label="Carrier ETA"
        party-to-alert="Carrier"
        :read-only="readOnly"
        class="mb-4"
        @condition-updated="updateCondition"
        :appointment="appointment"
        v-model="etaCondition"></eta-button-group>
      <v-stepper-items>
        <template v-for="(status, index) in statuses">
          <v-stepper-content
            :step="status.stepNumber"
            :key="`status-${index}`"
            class="pa-0"
            transition="fab-transition">
            <!-- Regular View -->
            <v-card class="card-content py-5" elevation="6" outlined v-if="!compact">
              <v-card-text>
                <v-container>
                  <v-row
                    class="justify-center"
                    :class="{ 'mb-5': $rolePermissions.canUpdateAppointmentStatus }">
                    <span>{{ statusMap[status.name].description }}</span>
                  </v-row>
                  <v-row
                    class="justify-center"
                    v-if="$rolePermissions.canUpdateAppointmentStatus && !readOnly">
                    <secondary-button
                      :loading="btnHappy_loading"
                      class="status-proceed-button"
                      @click="btnHappy_onClick(status)"
                      v-if="shouldShowNextHPButton(status)">
                      {{ getButtonLabel(statusMap[getNextHappyPathStatus(status)]) }}
                    </secondary-button>

                    <v-btn
                      outlined
                      data-testid="appointment-details-dialog-trigger-button"
                      v-if="checkForTriggerOnStatus(status.name)"
                      class="mr-2"
                      @click="activeTrigger = checkForTriggerOnStatus(status.name)">
                      <v-icon small class="mr-2">mdi-text-box-edit-outline</v-icon>
                      {{ checkForTriggerOnStatus(status.name).flow.name }}
                    </v-btn>

                    <v-btn
                      :loading="btnUndo_loading"
                      class="status-undo-button"
                      text
                      v-if="status.canUndo"
                      :class="
                        getButtonLabel(statusMap[getNextHappyPathStatus(status)]) &&
                        !status.isEndState
                          ? 'ml-4'
                          : ''
                      "
                      @click="btnUndo_onClick()">
                      <v-icon small class="mr-2">mdi-undo</v-icon>
                      {{ `Undo ${novaCore.breakWordsAtCaps(status.name)} Status` }}
                    </v-btn>
                    <v-btn
                      :loading="btnNonHappy_loading"
                      text
                      v-if="getNextNonHappyPathStatus(status) && !status.isEndState"
                      class="ml-4 status-undo-button"
                      @click="btnNonHappy_onClick(status)">
                      <v-icon
                        v-if="getStatusIcon(statusMap[getNextNonHappyPathStatus(status)])"
                        small
                        class="mr-2">
                        {{ getStatusIcon(statusMap[getNextNonHappyPathStatus(status)]) }}
                      </v-icon>
                      {{ getButtonLabel(statusMap[getNextNonHappyPathStatus(status)]) }}
                    </v-btn>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <!-- End Regular View -->

            <!-- Compact View -->
            <div v-else>
              <v-container>
                <v-row v-if="$rolePermissions.canUpdateAppointmentStatus">
                  <v-btn
                    text
                    block
                    @click="updateStatus(getNextHappyPathStatus(status))"
                    v-if="shouldShowNextHPButton(status)"
                    class="justify-start align-center no-text-transform">
                    <v-icon
                      small
                      :color="getNextHappyPathStatus(status).toLowerCase()"
                      class="mr-2">
                      mdi-circle
                    </v-icon>
                    {{
                      novaCore.capitalize(getButtonLabel(statusMap[getNextHappyPathStatus(status)]))
                    }}
                  </v-btn>
                  <v-btn
                    text
                    block
                    v-if="status.canUndo"
                    class="justify-start align-center no-text-transform"
                    @click="undoLatestStatus">
                    <v-icon small class="mr-2">mdi-undo</v-icon>
                    {{ `Undo ${novaCore.breakWordsAtCaps(status.name)} Status` }}
                  </v-btn>
                  <v-btn
                    text
                    block
                    class="justify-start align-center no-text-transform"
                    v-if="getNextNonHappyPathStatus(status) && !status.isEndState"
                    @click="updateStatus(getNextNonHappyPathStatus(status))">
                    <v-icon
                      v-if="getStatusIcon(statusMap[getNextNonHappyPathStatus(status)])"
                      small
                      class="mr-2">
                      {{ getStatusIcon(statusMap[getNextNonHappyPathStatus(status)]) }}
                    </v-icon>
                    <v-icon
                      v-else
                      small
                      :color="getNextNonHappyPathStatus(status).toLowerCase()"
                      class="mr-2">
                      mdi-circle
                    </v-icon>
                    {{ getButtonLabel(statusMap[getNextNonHappyPathStatus(status)]) }}
                  </v-btn>
                </v-row>
              </v-container>
            </div>
            <!-- End Compact View -->
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>

    <status-timeline-edit-dialog
      :external-activator="true"
      :status-to-edit="statusToEdit"
      :mixpanel-entry-point="mixpanelEntryPoint"
      :show-dialog="showEditDialog"
      :appointment="appointment"
      @close="closeEditor"></status-timeline-edit-dialog>

    <custom-forms-flow-dialog
      :external-activator="true"
      :warehouse="warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      :status-change="pendingStatusChange"
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="submitStatusWithCustomFormsData"></custom-forms-flow-dialog>
  </div>
</template>

<script>
import etaMixin from '@satellite/components/mixins/etaMixin';
import statusManagerMixin from '@satellite/components/mixins/statusManagerMixin';
import { AssetVisitEventType } from '@satellite/../nova/core';

const BTN_HAPPY = 'BTN_HAPPY';
const BTN_NON_HAPPY = 'BTN_NON_HAPPY';
const BTN_UNDO = 'BTN_UNDO';

/**
 * @displayName Status Manager
 */
export default {
  name: 'StatusManager',
  mixins: [etaMixin, statusManagerMixin],
  props: {
    mixpanelEntryPoint: {
      type: String,
      required: false
    },
    warehouseTriggers: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      lastBtnClicked: null
    };
  },
  computed: {
    btnHappy_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_HAPPY;
    },
    btnNonHappy_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_NON_HAPPY;
    },
    btnUndo_loading: function () {
      return this.loading && this.lastBtnClicked === BTN_UNDO;
    },
    warehouse() {
      // Used by statusManagerMixin
      return this.appointment?.dock?.warehouse;
    },
    isUnplannedAppointment() {
      return this.appointment?.assetVisit?.isPlanned === false;
    },
    isCarrierCheckin() {
      return this.appointment?.isCheckedInByCarrier;
    },
    isAssetDeparted() {
      return this.appointment?.assetVisit?.assetVisitEvents.some(
        event => event.eventType === AssetVisitEventType.Departed
      );
    },
    hasCheckinStatus() {
      return this.isCarrierCheckin || this.isUnplannedAppointment || this.isAssetDeparted;
    }
  },
  methods: {
    btnHappy_onClick(status) {
      if (this.loading) {
        return;
      }
      this.updateStatus(this.getNextHappyPathStatus(status));
      this.lastBtnClicked = BTN_HAPPY;
    },
    btnNonHappy_onClick(status) {
      if (this.loading) {
        return;
      }
      this.updateStatus(this.getNextNonHappyPathStatus(status));
      this.lastBtnClicked = BTN_NON_HAPPY;
    },
    btnUndo_onClick() {
      if (this.loading) {
        return;
      }
      this.undoLatestStatus();
      this.lastBtnClicked = BTN_UNDO;
    }
  }
};
</script>
