// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  async submitFeedback({}, feedback) {
    let response = await axios.post('feedback', {
      text: feedback
    });

    return response;
  }
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  name: 'Feedback',
  state,
  getters,
  actions,
  mutations
};
