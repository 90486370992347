<template>
  <div>
    <v-card>
      <v-card-title class="pb-0 pt-5">
        <v-row data-testid="carrier-reporting-filters">
          <v-col cols="3">
            <text-field
              v-model="search"
              append-icon="mdi-magnify"
              hide-details
              height="26px"
              dense
              label="Search Carriers"></text-field>
          </v-col>
          <v-col cols="3">
            <warehouse-multi-select
              :is-flex="false"
              hide-icon
              :auto-select-all="!getLastSelectedWarehouseIds()?.length"
              ref="reportingWarehouseSelect"
              dense
              placeholder="Warehouses"
              :joins="['docks||id,name']"
              :select-fields="['id,name,facilityNumber']"
              @input="
                ws =>
                  persistLastSelectedData(
                    'warehouseIds',
                    ws?.map(w => w.id)
                  )
              "
              :auto-select-by-ids="getLastSelectedWarehouseIds()"
              label="Warehouses"
              v-model="filters.selectedWarehouses"
              show-store-number></warehouse-multi-select>
          </v-col>
          <v-col cols="3">
            <date-range
              ref="apptReportDateRange"
              height="26px"
              :dense="true"
              clearable
              label="Appointment Date Range"
              :initial-range="[]"
              v-model="filters.appointmentDateRange"></date-range>
          </v-col>
          <v-spacer></v-spacer>
          <primary-button
            class="mt-1"
            :loading="buildingExcelData"
            before-icon="microsoft-excel"
            @click="exportExcel"
            >Export to Excel</primary-button
          >
        </v-row>
      </v-card-title>
      <div class="d-flex justify-center mt-5">
        <v-alert outlined type="info" dense border="left" class="d-inline-flex">
          The carrier insights page only shows data for <strong>completed</strong> appointments, it
          may not reflect the total appointments for each carrier.
        </v-alert>
      </div>
      <v-data-table
        :headers="headers"
        :items="carriers"
        :search="search"
        data-testid="carrier-metrics-table"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="$data.$globalLoading">
        <template v-slot:item.onTimePct="{ item }">
          {{ formatDataPoint(item.onTimePct, '%') }}
        </template>

        <template v-slot:item.latePct="{ item }">
          {{ formatDataPoint(item.latePct, '%') }}
        </template>

        <template v-slot:item.cancelPct="{ item }">
          {{ formatDataPoint(item.cancelPct, '%') }}
        </template>

        <template v-slot:item.noShowPct="{ item }">
          {{ formatDataPoint(item.noShowPct, '%') }}
        </template>

        <template v-slot:item.rescheduledPct="{ item }">
          {{ formatDataPoint(item.rescheduledPct, '%') }}
        </template>

        <template v-slot:item.avgCancelLeadTimeInMinutes="{ item }">
          {{
            item.avgCancelLeadTimeInMinutes
              ? novaCore.formatMinutesToHuman(item.avgCancelLeadTimeInMinutes)
              : '----'
          }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex" data-testid="carrier-table-actions-cell">
            <carrier-appointments-list-dialog
              icon-class="mr-2"
              :carrier="item"
              tooltip-text="View appointments for this carrier"
              :appointment-ids="item.appointmentIds"></carrier-appointments-list-dialog>
            <pie-chart-dialog
              :data="getPieData(item)"
              :colors="['#00a66c', '#775DD0', '#FEB019', '#fe4560']"
              :dialog-title="item.name || '<NO NAME>'"
              :labels="['On Time', 'Late', 'Canceled', 'No Show']"></pie-chart-dialog>
          </div>
        </template>

        <template v-slot:body.append>
          <tr class="font-weight-bold primary white--text">
            <td>All Carriers</td>
            <td>----</td>
            <td>{{ allCarrierData.totalAppointments }}</td>
            <td>{{ formatDataPoint(allCarrierData.onTimePct, '%') }}</td>
            <td>{{ formatDataPoint(allCarrierData.latePct, '%') }}</td>
            <td>{{ formatDataPoint(allCarrierData.cancelPct, '%') }}</td>
            <td>{{ formatDataPoint(allCarrierData.noShowPct, '%') }}</td>
            <td>{{ formatDataPoint(allCarrierData.rescheduledPct, '%') }}</td>
            <td>
              {{
                allCarrierData.avgCancelLeadTimeInMinutes
                  ? novaCore.formatMinutesToHuman(allCarrierData.avgCancelLeadTimeInMinutes)
                  : '----'
              }}
            </td>
            <td class="pr-5">
              <pie-chart-dialog
                icon-color="white"
                icon-class="float-right"
                :data="getPieData(allCarrierData)"
                :colors="['#00a66c', '#775DD0', '#FEB019', '#fe4560']"
                dialog-title="All Carriers"
                :labels="['On Time', 'Late', 'Canceled', 'No Show']"></pie-chart-dialog>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import { utils, writeFile } from 'xlsx';
import moment from 'moment-timezone';
import persistFilterMixin from '@/modules/insights/components/mixins/persistFilterMixin';

/**
 * User List
 * @displayName Carrier List
 */
export default {
  mixins: [dataListMixin, persistFilterMixin],
  data() {
    return {
      headers: [
        { text: 'Carrier Name', value: 'name', sortable: true },
        { text: 'SCAC', value: 'scac', sortable: true },
        { text: '# of Appts', value: 'totalAppointments', sortable: true },
        { text: 'On Time %', value: 'onTimePct', sortable: true },
        { text: 'Late %', value: 'latePct', sortable: true },
        { text: 'Cancel %', value: 'cancelPct', sortable: true },
        { text: 'No Show %', value: 'noShowPct', sortable: true },
        { text: 'Rescheduled %', value: 'rescheduledPct', sortable: true },
        { text: 'Avg Cancel Lead Time', value: 'avgCancelLeadTimeInMinutes', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      search: null,
      carrierMetrics: '',
      carriers: [],
      buildingExcelData: false,
      sortDesc: [true],
      sortBy: ['totalAppointments'],
      allCarrierData: [],
      filters: {
        selectedWarehouses: [],
        appointmentDateRange: []
      },
      selectionPersistKey: 'nova_insights_carrier_metrics',
      currentRequests: []
    };
  },
  watch: {
    carrierMetrics() {
      this.carriers = Object.values(this.carrierMetrics.list);
      this.allCarrierData = this.carrierMetrics.total;
    },
    filters() {
      this.getData();
    },
    carriers() {
      this.getData();
    }
  },
  methods: {
    async getData() {
      const url = `/metrics/carrier/status-percentages`;
      const params = {
        warehouseIds: this.filters.selectedWarehouses.map(wh => wh.id),
        appointmentFromDate: this.filters.appointmentDateRange[0],
        appointmentToDate: this.filters.appointmentDateRange[1]
      };

      const semaphoreKey = encodeURI(`${url}-${JSON.stringify(params)}`);

      if (this.currentRequests.includes(semaphoreKey)) {
        return;
      }
      this.currentRequests.push(semaphoreKey);

      axios
        .get(url, {
          params
        })
        .then(response => {
          if (response?.data) {
            this.carrierMetrics = response.data.data;
          }
        })
        .finally(
          () => (this.currentRequests = this.currentRequests.filter(r => r !== semaphoreKey))
        );
    },
    formatDataPoint(value, suffix = '', prefix = '', placeholder = '----') {
      return value && !isNaN(value) ? `${prefix}${value}${suffix}` : placeholder;
    },
    getPieData(dataObj) {
      return [
        parseInt(dataObj.totalOnTimeAppointments),
        parseInt(dataObj.totalLateAppointments),
        parseInt(dataObj.totalCancelledAppointments),
        parseInt(dataObj.totalNoShowAppointments)
      ];
    },
    getLastSelectedWarehouseIds() {
      const filterData = this.getPersistedFilterData();
      if (filterData?.warehouseIds) {
        return filterData.warehouseIds;
      }
    },
    excelFileName() {
      return `carrier_insights_${moment().format(this.novaCore.DateTimeFormats.DateDashed)}.xlsx`;
    },
    exportExcel() {
      this.buildingExcelData = true;
      const data = [];

      this.carriers.forEach(carrier => {
        const row = {
          'Carrier Name': carrier.name,
          SCAC: carrier.scac,
          '# of Appts': carrier.totalAppointments,
          'On Time %': this.formatDataPoint(carrier.onTimePct, '%'),
          'Late %': this.formatDataPoint(carrier.latePct, '%'),
          'Cancel %': this.formatDataPoint(carrier.cancelPct, '%'),
          'No Show %': this.formatDataPoint(carrier.noShowPct, '%'),
          'Rescheduled %': this.formatDataPoint(carrier.rescheduledPct, '%'),
          'Avg Cancel Lead Time': this.novaCore.formatMinutesToHuman(
            carrier.avgCancelLeadTimeInMinutes
          )
        };

        data.push(row);
      });

      const wb = utils.book_new();

      utils.book_append_sheet(wb, utils.json_to_sheet(data), 'Carriers');

      writeFile(wb, this.excelFileName());
      this.trackExportMixpanelEvent();
      this.buildingExcelData = false;
    },
    trackExportMixpanelEvent() {
      this.mixpanel.track(this.mixpanel.events.MODULE.REPORTING.CARRIER_REPORT_EXPORTED, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id
      });
    }
  }
};
</script>
