<template>
  <form-base
    :class="{ disabled: readOnly, 'half-width': !readOnly, 'pb-4': readOnly }"
    fluid
    class="px-8 pt-6 hours-of-operation-form"
    :hide-required-legend="true">
    <template #form>
      <v-form>
        <span class="text-h6">Standard Hours ({{ warehouse.timezone }})</span>
        <hours-of-operation-item
          :read-only="readOnly"
          v-for="weekday in weekdays"
          :date-range-select-items="dateRangeSelectItems"
          :key="weekday"
          :weekday="weekday"
          @copy-schedule="copySchedule"
          v-model="schedule[weekday]"></hours-of-operation-item>

        <div class="text-h6 mt-12">Closed dates</div>
        <p class="mb-0">Set closed dates for holidays, events or other exceptional dates</p>

        <days-off
          v-if="warehouse.id"
          :schedule="warehouse.schedule"
          :warehouse="warehouse"
          @update-closed-intervals="updateClosedIntervals"></days-off>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        :disable-confirm="!areClosedIntervalsValid || readOnly"
        :loading="loading"
        :hide-cancel="true"
        confirm-label="Save Operating Hours"
        @confirm="updateSchedule"></action-group>
    </template>
  </form-base>
</template>

<script>
/**
 * Hours of Operation (warehouse specific)
 * @displayName Hours of Operation Form
 */
export default {
  name: 'hours-of-operation-form',
  props: {
    /**
     * Warehouse entity
     */
    warehouse: {
      type: Object,
      required: true
    },
    /**
     * Is form readonly
     */
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    /**
     * array of lowercase weekdays ["sunday", "monday"...]
     * @returns {string[]}
     */
    weekdays() {
      return momentjs.weekdays().map(weekday => weekday.toLowerCase());
    },
    /**
     * Array of objects containing items split into specified interval length for use in date range select items
     * [{text: "12:00 am", value: "0:00"}, {text: "12:30 am, value: "0:30"}...]
     * @returns {*[]}
     */
    dateRangeSelectItems() {
      const settings = this.novaCore.makeEntitySettings(this.novaCore.DatabaseEntities.Warehouse, {
        ...this.warehouse,
        org: this.$org
      });

      return this.novaCore.makeFullDayClockOptions(
        this.intervalLength,
        this.$isMilitaryTimeEnabled({ ...this.warehouse, settings })
      );
    }
  },
  data() {
    return {
      schedule: {},
      loading: false,
      intervalLength: 15, // This will become a setting at some point - easy to swap out here
      closedIntervals: [],
      areClosedIntervalsValid: true,
      isScheduleInitialized: false
    };
  },
  methods: {
    /**
     * Dynamically set schedule's reactive properties {sunday: [], monday: []...}
     * @public
     */
    setWeekdayData() {
      this.weekdays.map(weekday => {
        this.$set(this.schedule, weekday, []);
      });
    },
    /**
     * Copy warehouse schedule to component data
     * @public
     */
    setWarehouseSchedule() {
      this.schedule = { ...this.warehouse.schedule };
    },
    /**
     * Copy specific schedule to provided days
     * @public
     * @param {array} days
     * @param {object} schedule
     */
    copySchedule({ days, schedule }) {
      let newSchedule = { ...this.schedule };
      days.map(day => {
        newSchedule[day] = this.novaCore.deepClone(schedule);
      });
      this.schedule = newSchedule;
    },
    /**
     * Save warehouse schedule
     * @public
     */
    async updateSchedule() {
      let schedule = { ...this.schedule };
      schedule.version = this.warehouse?.schedule?.version || 1;
      schedule.closedIntervals = [...this.closedIntervals].map(interval => {
        return {
          start: this.novaCore.renderTimezoneInUtc(interval.start, this.warehouse.timezone),
          end: this.novaCore.renderTimezoneInUtc(interval.end, this.warehouse.timezone)
        };
      });
      if (schedule.closedIntervals.length < 1) {
        delete schedule.closedIntervals;
      }
      this.isScheduleInitialized = false;
      this.warehouse.schedule = schedule;
      this.$eventHub.$emit('update-Warehouse', this.warehouse);
      await this.$store.dispatch('Warehouses/updateWarehouse', {
        id: this.warehouse.id,
        schedule: this.warehouse.schedule
      });
      /**
       * Emits schedule-updated event
       * @event schedule-updated
       */
      this.$emit('schedule-updated');
      this.setDirtyStatus(false);
    },
    updateClosedIntervals(closedIntervals) {
      this.closedIntervals = closedIntervals;
    }
  },
  mounted() {
    this.setWeekdayData();
    this.setWarehouseSchedule();

    // Ensure the form is clean after loading all data
    this.$nextTick(() => this.setDirtyStatus(false));
  },
  watch: {
    warehouse() {
      this.setWarehouseSchedule();
    },
    schedule: {
      deep: true,
      handler() {
        this.setDirtyStatus(this.isScheduleInitialized);
        this.isScheduleInitialized = true;
      }
    },
    closedIntervals() {
      this.setDirtyStatus(true);
    }
  }
};
</script>
