<template>
  <div>
    <form-base :header="header" v-if="!submitted">
      <template slot="form">
        <v-form ref="form" v-on:submit.prevent @keydown.native.enter="submit">
          <v-container class="px-0">
            <p class="font-weight-bold">
              Please feel free to share any issues, criticisms, ideas, or just general comments
              about your experience with Opendock NOVA:
            </p>
            <text-area
              :required="true"
              v-model="feedback"
              :rules="$validator.rules.feedback"
              label="Comments">
            </text-area>
          </v-container>
        </v-form>
      </template>
      <template slot="form-actions">
        <action-group @cancel="cancel" confirm-icon="send" confirm-label="Send" @confirm="submit">
        </action-group>
      </template>
    </form-base>

    <template v-else>
      <v-container>
        <h2>Submitted!</h2>
        <p>Thank you for your feedback, we greatly appreciate it!</p>
      </v-container>
      <action-group
        :hide-confirm="true"
        cancel-icon=""
        cancel-label="Close"
        @cancel="cancel"></action-group>
    </template>
  </div>
</template>

<script>
/**
 * Feedback Form
 * @displayName Feedback Form
 */
export default {
  props: {
    /**
     * Form Header
     */
    header: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      feedback: '',
      submitted: false
    };
  },
  methods: {
    /**
     * Submit form
     * @public
     * @returns {Promise<void>}
     */
    async submit() {
      if (this.$refs.form.validate()) {
        const response = await this.$store.dispatch('Feedback/submitFeedback', this.feedback);

        if (response) {
          this.submitted = true;
        }
      }
    },
    cancel() {
      /**
       * Emits close event
       * @event close
       */
      this.$emit('close');
    }
  }
};
</script>
