<template>
  <div class="px-2 py-1">
    <div class="header mb-2">
      <div class="d-flex align-center">
        <v-icon class="mr-2 white--text">mdi-truck</v-icon>
        <strong>{{ companyName }}</strong>
        <template v-if="event.isRecurringAppointment">
          <v-spacer></v-spacer>
          <v-icon small color="white" class="mr-1">mdi-repeat</v-icon>
          <span class="font-size-x-small uppercase">Recurring</span>
        </template>
      </div>
      <span class="date-label">{{ date }}</span>
    </div>

    <table class="event-tooltip-table">
      <thead></thead>
      <tbody>
        <tr>
          <td class="pr-4">Confirmation #:</td>
          <td class="font-weight-bold">{{ event.confirmationNumber }}</td>
        </tr>
        <tr>
          <td class="pr-4">Status:</td>
          <td>
            <v-chip label x-small :class="statusStyles.textColor" :color="statusStyles.bgColor">
              <v-icon
                x-small
                color="requested darken-3"
                class="requested-icon"
                v-if="novaCore.isRequested(event.status)"
                >mdi-clock-alert</v-icon
              >
              {{ novaCore.breakWordsAtCaps(event.status) }}
            </v-chip>
          </td>
        </tr>
        <tr>
          <td class="pr-4">Tags:</td>
          <td class="font-weight-bold">
            <div v-if="tags && tags.length">
              <template v-for="tag in tags">
                <v-chip
                  v-if="tag.color"
                  :key="tag.name"
                  x-small
                  class="px-2 font-weight-bold mr-1"
                  :color="tag.color"
                  :text-color="tag.textColor">
                  {{ tag.name }}
                </v-chip>
                <v-chip
                  v-else
                  :key="tag.name"
                  x-small
                  class="px-2 mb-1 generic-tag"
                  :class="{ 'dark-bg': getGenericTagClass }"
                  outlined>
                  {{ tag.name }}
                </v-chip>
              </template>
            </div>
            <span v-else>----</span>
          </td>
        </tr>
        <tr v-if="event.eta">
          <td class="pr-4">ETA:</td>
          <td>
            {{ novaCore.renderUtcInTimezone(event.eta, event.dock.warehouse.timezone) }}
          </td>
        </tr>
        <tr v-if="$getSettingValue('referenceNumberIsVisible', event.dock.warehouse.settings)">
          <td class="pr-4">
            {{ $getSettingValue('referenceNumberDisplayName', event.dock.warehouse.settings) }}:
          </td>
          <td class="font-weight-bold">{{ event.refNumber }}</td>
        </tr>
        <tr>
          <td class="pr-4">Dock:</td>
          <td class="font-weight-bold">{{ event.readableDockName }}</td>
        </tr>
        <tr>
          <td class="pr-4">Load Type:</td>
          <td class="font-weight-bold">
            {{ event.loadType.name }} - {{ event.loadType.direction }}
          </td>
        </tr>
        <tr>
          <td class="pr-4">Duration:</td>
          <td class="font-weight-bold">{{ getEventDurationString(event) }}</td>
        </tr>
        <tr v-for="(field, index) in limitedCustomFields" :key="`custom-field-popup-${index}`">
          <td class="pr-4">{{ field.label }}:</td>
          <td class="font-weight-bold">
            <span class="text-pre-wrap" v-html="getFieldValue(field)"></span>
          </td>
        </tr>
        <tr v-if="customFieldOverflowCount">
          <td class="font-weight-bold">+ {{ customFieldOverflowCount }} more</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { AppointmentStatus } from '@satellite/../nova/core';
const statusesWithBlackText = ['cancelled', 'requested', 'inprogress'];
/**
 * Calendar event tooltip
 * @displayName Event Tooltip
 */
export default {
  props: {
    /**
     * Calendar event
     */
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    date() {
      const tz = null;
      return `${this.novaCore.getFormattedTime(
        this.event.start,
        this.novaCore.DateTimeFormats.FullDayMonthLong
      )} • ${this.novaCore.formatDateTimeWithMilitarySupport(
        this.event.start,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      )} - ${this.novaCore.formatDateTimeWithMilitarySupport(
        this.event.end,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      )}`;
    },
    tags() {
      if (!this.event.tags?.length) {
        return [];
      }

      const allTags = this.novaCore.deepClone(this.event.tags);
      const customTags = allTags.filter(t =>
        Boolean(this.util.getCustomTagData(this.$org.customTags, t))
      );
      // Sort custom tags to front but keep the original order overall
      const tags = [...new Set([...customTags, ...allTags])];
      // TODO part of all of this logic is duplicated in the calendar store - abstract
      const formattedTags = tags.map(tag => {
        const rescheduledRegExp =
          /^Rescheduled - [0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/;
        if (tag.match(rescheduledRegExp)) {
          tag = 'Rescheduled';
        }
        const customTagData = this.util.getCustomTagData(this.$org.customTags, tag);

        return {
          name: tag,
          color: customTagData?.color || null,
          textColor: customTagData?.textColor || null
        };
      });

      // Removes duplicated tags (e.g. #Rescheduled, #Rescheduled)
      const uniqueFormattedTags = [...new Set(formattedTags)];

      return uniqueFormattedTags || [];
    },
    customFieldOverflowCount() {
      return this.customFieldsWithValue.length - this.limitedCustomFields.length;
    },
    limitedCustomFields() {
      return this.customFieldsWithValue.slice(0, this.customFieldsToDisplay);
    },
    customFieldsWithValue() {
      return this.event.customFields?.length > 0
        ? this.event.customFields.filter(field => {
            return !!field.value;
          })
        : [];
    },
    statusStyles() {
      let bgColor = this.event.status.toLowerCase();
      let textColor = statusesWithBlackText.includes(bgColor) ? 'black--text' : 'white--text';
      return { bgColor: bgColor, textColor: textColor };
    },
    companyName() {
      return this.event.user?.company?.name || 'No carrier';
    },
    getGenericTagClass() {
      const darkStatusColors = [
        AppointmentStatus.Arrived,
        AppointmentStatus.NoShow,
        AppointmentStatus.Completed,
        AppointmentStatus.Scheduled
      ];
      return darkStatusColors.includes(this.event?.status);
    }
  },
  data() {
    return {
      customFieldsToDisplay: 5
    };
  },
  methods: {
    getFieldValue(field) {
      return this.novaCore.getCustomFieldFormattedValue(field, {
        [this.novaCore.CustomFieldType.Timestamp]: {
          timezone: this.event.dock.warehouse.timezone,
          formatAsMilitary: this.$isMilitaryTimeEnabled(this.$org)
        }
      });
    },
    getEventDurationString(event) {
      const duration = this.novaCore.duration_min(event);
      return `${duration} minutes`;
    }
  }
};
</script>
