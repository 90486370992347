<template>
  <dialog-base
    :key="renderKey"
    :loading="false"
    v-bind="$props"
    :header="dialogTitle"
    loading-message="Creating Appointments ..."
    @close="close"
    max-width="100%"
    has-dialog-actions
    :value="showDialog"
    width="600px">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button v-else v-on="slotProps.on" v-bind="slotProps.attrs">
        Create Recurrence
      </primary-button>
    </template>
    <template v-slot:body>
      <create-recurring-series-form
        @update-title="updateDialogTitle"
        :appointment="appointment"
        @close="close"></create-recurring-series-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

export default {
  name: 'CreateRecurringSeriesDialog',
  mixins: [dialogMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogTitle: 'Create Recurrence'
    };
  },
  methods: {
    updateDialogTitle(title) {
      this.dialogTitle = title;
    }
  }
};
</script>
