<template>
  <dialog-base
    :key="renderKey"
    :loading="loading"
    v-bind="$props"
    header="Reserve Details"
    loading-message=""
    @close="close"
    max-width="100%"
    :value="showDialog"
    width="900px">
    <template v-slot:header-actions>
      <appointment-action-group
        :parent-appointment="parentAppointment"
        :appointment="appointment"
        :appointment-actions="appointmentActions"></appointment-action-group>
    </template>
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button v-on="slotProps.on" v-bind="slotProps.attrs">View Reserve</primary-button>
    </template>
    <template v-slot:body>
      <v-divider class="mb-8"></v-divider>
      <reserve-details
        :parent-appointment="parentAppointment"
        @close="close"
        :appointment="appointment"
        :should-edit-status="true"
        :should-display-cancel-button="false"></reserve-details>
    </template>
    <template
      v-slot:dialog-actions
      v-if="(canCancel || isCancelled) && !appointment.isRecurringAppointment">
      <v-btn
        v-if="!isCancelled && $rolePermissions.canUpdateAppointment"
        elevation="0"
        class="text__error font-weight-bold"
        color="white"
        @click="deleteReserve"
        :disabled="appointment.status === novaCore.AppointmentStatus.Cancelled">
        <v-icon class="mr-2">{{ cancelButtonIcon }}</v-icon>
        Delete Reserve
      </v-btn>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import appointmentMixin from '@/components/mixins/appointmentMixin';

/**
 * Dialog for reserve details
 * @displayName Reserve Details Dialog
 */
export default {
  mixins: [dialogMixin, appointmentMixin],
  props: {
    /**
     * Appointment Entity
     */
    appointment: {
      type: Object,
      required: true
    }
  },
  computed: {
    appointmentActions() {
      return {
        recur: {
          icon: 'repeat',
          action: 'openCreateRecurringSeriesDialog',
          show:
            this.$rolePermissions.canCreateAppointment && !this.appointment.isRecurringAppointment,
          tooltip: 'Create Recurrence'
        },
        deleteRecur: {
          icon: 'repeat-off',
          action: 'openDeleteRecurringSeriesDialog',
          textColor: 'error',
          show:
            this.$rolePermissions.canCreateAppointment &&
            this.novaCore.isRecurringAppointment(this.appointment),
          tooltip: 'Delete Recurrence'
        },
        editRecur: {},
        edit: {
          icon: 'pencil-outline',
          action: 'openEditReserveDialog',
          show: this.$rolePermissions.canUpdateAppointment,
          tooltip: 'Edit Details'
        }
      };
    }
  },
  data() {
    return {
      loading: false,
      parentAppointment: null
    };
  },
  methods: {
    async deleteReserve() {
      try {
        await this.$store.dispatch('Appointments/deleteReserve', {
          id: this.appointment.id
        });
        this.$store.commit('Calendar/setSelectedEvent', null);
        this.close();
      } catch (e) {
        console.log('Error deleting Reserve: ', e);
      }
    },
    async fetchParentAppointment() {
      this.loading = true;
      this.loadingMessage = 'Loading Appointment';
      const appt = await this.$store
        .dispatch('Appointments/getAppointmentById', this.appointment.recurringParentId)
        .finally(() => {
          this.loading = false;
        });
      if (appt) {
        this.parentAppointment = appt;
      }
    }
  },
  async mounted() {
    this.$eventHub.$on('close-appointment-details', this.close);
  },
  destroyed() {
    this.$eventHub.$off('close-appointment-details', this.close);
  }
};
</script>
