<template>
  <form-base>
    <template #form>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col>
              <text-field
                :required="true"
                type="text"
                v-model="userInvite.email"
                :rules="[...$validator.rules.required('Email'), ...$validator.rules.email]"
                label="Email"></text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <text-field
                :required="true"
                type="text"
                v-model="userInvite.firstName"
                :rules="$validator.rules.required('First Name')"
                label="First Name"></text-field>
            </v-col>
            <v-col>
              <text-field
                :required="true"
                type="text"
                v-model="userInvite.lastName"
                :rules="$validator.rules.required('Last Name')"
                label="Last Name"></text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <role-select
                v-model="userInvite.role"
                data-testid="role-select"
                :excluded-roles="[
                  novaCore.UserRole.BASE,
                  novaCore.UserRole.CARRIER_ADMIN,
                  novaCore.UserRole.CARRIER
                ]"
                :parent-role="$me.role"
                :rules="$validator.rules.required('Role')"></role-select>
            </v-col>
          </v-row>
          <template>
            <user-restriction-switch
              :read-only="novaCore.isUserWarehouseRestricted($me)"
              :role="userInvite?.role"
              v-model="shouldTargetUserBeRestrictedByLocation"
              class="mt-1"></user-restriction-switch>

            <div
              v-if="
                shouldTargetUserBeRestrictedByLocation &&
                novaCore.canUserRoleHaveWarehouseAccessList(userInvite.role)
              ">
              <v-row>
                <v-col>
                  <basic-warehouse-select
                    :restricted-warehouse-ids="currentUserWarehouseAccessList"
                    :filter-locally="false"
                    multiple
                    v-model="selectedWarehouses"></basic-warehouse-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="my-3">Max warehouses per user: {{ novaCore.MAX_WAREHOUSE_PER_USER }}</p>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-container>
      </v-form>
    </template>
    <template #form-actions>
      <action-group
        @cancel="$emit('close')"
        confirm-icon="plus"
        confirm-label="Invite User"
        @confirm="submit"></action-group>
    </template>
  </form-base>
</template>

<script>
import userRestrictionMixin from '@satellite/components/mixins/userRestrictionMixin';

/**
 * Invite User Form
 * @displayName Invite User Form
 */
export default {
  mixins: [userRestrictionMixin],
  props: {
    postSubmissionRouteName: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      userInvite: {
        email: '',
        firstName: '',
        lastName: '',
        role: ''
      }
    };
  },
  methods: {
    /**
     * Submit "Action: Invited User" to mixpanel
     * @public
     */
    trackMixPanel() {
      const entryPoint = this.mixpanel.getEntryPoint(this, [
        { entryPoint: 'Users Page', component: 'users-page' }
      ]);

      this.mixpanel.track(this.mixpanel.events.MODULE.USER.INVITED, {
        'User Invite Email': this.userInvite.email,
        'User Invite Name': `${this.userInvite.firstName} ${this.userInvite.lastName}`,
        'User Invite Role': this.userInvite.role,
        'User Warehouse Access List': this.selectedWarehouseAccessList.join(', '),
        'Entry Point': entryPoint,
        'Org ID': this.$org.id,
        'Org Name': this.$org.name
      });
    },
    /**
     * Submit Form
     * @public
     */
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const data = { ...this.userInvite };
      if (this.shouldTargetUserBeRestrictedByLocation) {
        data.warehouseAccessList = this.selectedWarehouseAccessList;
      }

      this.$store.dispatch('Users/inviteUser', data).then(() => {
        this.trackMixPanel();
        this.notify(`User "${this.userInvite.email}" has been invited!`, 'success', {
          duration: 5000
        });
        this.$emit('close');
        if (this.postSubmissionRouteName) {
          this.navigate(this.postSubmissionRouteName);
        }
      });
    }
  }
};
</script>
