<template>
  <div id="beta-page">
    <div class="hero text-center">
      <div class="content">
        <img :src="$logoPath" alt="opendock graphic" class="d-inline-block mr-1 opendock-logo" />
        <span class="d-block text-uppercase primary--text font-weight-bold my-8"
          >Join our Free Opendock Beta Program*</span
        >
        <span class="text-h3 white--text d-block py-1">Improve your dock</span>
        <span class="text-h3 white--text d-block py-1 font-weight-bold">scheduling process!</span>
        <p class="py-8 white--text">
          Tired of spreadsheets, email calendars, and sticky notes? <br />
          Opendock is the dock scheduling tool of choice for over 2,000 warehouses in North America.
          We are building on that success with our next-generation dock scheduling system and
          actively looking for warehouse partners to guide the ultimate vision. <br /><br />
          We are bringing the same scheduling efficiency plus new updates like:
          <span class="font-weight-bold"
            >in/out time tracking, enhanced reporting, and carrier scorecards</span
          >. Interested in learning more about the new product with the first 3 months free? Submit
          your email below and our team will reach out to you to schedule a demo.
        </p>

        <div class="fields">
          <primary-button @click="scrollToForm" x-large block
            >Join Opendock Beta for FREE</primary-button
          >
        </div>
      </div>
    </div>

    <v-container class="py-16">
      <v-row>
        <v-col class="d-flex justify-center align-end">
          <img src="/images/beta/calendar_icon.svg" alt="calendar graphic" />
        </v-col>
        <v-col class="d-flex justify-center align-end">
          <img src="/images/beta/truck_icon.svg" alt="truck graphic" />
        </v-col>
        <v-col class="d-flex justify-center align-end">
          <img src="/images/beta/notification_icon.svg" alt="notification graphic" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span class="text-h5 font-weight-bold d-block pb-4">Easy to use</span>
          <p>
            Setup your warehouse and start booking appointments in less than 1 hour with simple
            click to book functionality
          </p>
        </v-col>
        <v-col class="text-center">
          <span class="text-h5 font-weight-bold d-block pb-4">In/out time tracking</span>
          <p>
            Avoid detention costs and scorecard your carrier on-time delivery with easy in/out time
            recording
          </p>
        </v-col>
        <v-col class="text-center">
          <span class="text-h5 font-weight-bold d-block pb-4">Real-time notifications</span>
          <p>
            Notify your carriers, customers, and suppliers about appointment details and updates
            automatically over email
          </p>
        </v-col>
      </v-row>

      <div class="offset-content d-flex justify-center">
        <v-card elevation="5" class="pa-md-12 pa-sm-2 email-card" id="beta-form-container">
          <v-card-title class="justify-center">
            <span class="text-h4"
              >Want to join our <span class="font-weight-bold">Beta Program?</span></span
            >
          </v-card-title>
          <v-card-text>
            <p class="text-h6">
              Learn more about our <span class="font-weight-bold">Free Beta Program*</span> <br />by
              submitting your email address.
            </p>

            <div class="pa-8 pt-3 fields">
              <div id="hubspot-form" v-once></div>
            </div>
          </v-card-text>
        </v-card>
        <img
          id="img-booking"
          src="/images/beta/nova_booking_screen.png"
          alt="booking graphic"
          class="d-inline-block" />
      </div>
    </v-container>

    <div class="gradient-section">
      <p class="text-center">
        *Receive the <span class="font-weight-bold">first three months free</span> for partnering
        with the Opendock team and providing valuable feedback. <br />You will work closely with our
        product and engineering teams to help us build a better solution for you. <br />There are no
        hidden fees. No contracts. No commitments.
      </p>
      <br />
      <p class="text-center font-weight-bold mt-8">Copyright ©2021 Opendock</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToForm() {
      this.util.scrollToElement(document.getElementById('beta-form-container'));
      document.getElementById('hubspot-form').querySelector('input').focus();
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5041527',
          formId: 'ed24c363-971f-4a9a-bcc7-cb1e9ddfb6ee',
          target: '#hubspot-form',
          translations: {
            en: {
              submitText: 'Join Opendock beta for free'
            }
          }
        });
      }
    });
  }
};
</script>
<style lang="scss" @scoped>
#hubspot-form {
  input {
    padding-left: 5px;
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    border-style: solid;
    border-width: thin;
    min-height: 40px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.42);
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
      0 1px 5px 0 rgb(0 0 0 / 12%);
  }
  label {
    margin-top: 15px;
    text-align: left;
    display: block;
    margin-left: 8px;
  }
  input.hs-button {
    display: block;
    padding-left: 0;
    width: 94%;
    margin-top: 15px;
    margin-left: 6px;
    text-align: center;
    border-radius: 4px;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0.0892857143em;
    text-transform: uppercase;
    vertical-align: middle;
    font-family: 'Barlow', sans-serif;
  }
  .invalid.error {
    background-color: white !important;
  }
  .hs-error-msgs {
    list-style-type: none;
    padding-left: 0;
    li label {
      margin-top: 1px;
    }
  }
}

@media screen and (max-width: 1024px) {
  #img-booking {
    display: none !important;
  }
}
</style>
