<template>
  <div class="full-height d-flex flex-column justify-center align-center">
    <img class="welcome-image" alt="welcome to Opendock Nova" :src="welcomeImg" />
    <h3 class="mt-4 mb-2">{{ greeting }}</h3>
    <p class="text-center font-size-small">
      In order to fully use Opendock, you first need to
      <strong>create your Organization</strong>.<br />
      Don't worry, our <strong>Getting Started Checklist</strong> will guide you through it.
    </p>
    <create-org-dialog></create-org-dialog>
  </div>
</template>

<script>
import welcomeImg from '@/../public/images/welcome.png';
/**
 * Card displayed when no org has been created yet
 * @displayName Org Required Card
 */
export default {
  name: 'new-org-welcome',
  computed: {
    greeting() {
      return `Hello, ${this.$me?.firstName} ${this.$me?.lastName}`;
    },
    welcomeImg() {
      return welcomeImg;
    }
  }
};
</script>
