<template>
  <div>
    <v-card :class="flat ? 'elevation-0' : ''">
      <v-card-title>
        <v-row align="center" class="search-field">
          <v-col md="6">
            <text-field
              class="mb-5"
              v-model="filters.searchStr"
              append-icon="mdi-magnify"
              label="Search users"
              persistent-label></text-field>
          </v-col>
          <v-col>
            <v-switch
              v-if="novaCore.isInternalUser($me) && !orgId"
              class="mb-9"
              v-model="filters.showBaseUsers"
              inset
              label="Show Base Users"></v-switch>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <invite-user-dialog
          v-if="novaCore.canUserInviteUsers($me)"
          class="action-button"></invite-user-dialog>
      </v-card-title>
      <v-data-table
        v-model="deleting"
        :headers="headers"
        :items="$users"
        :loading="loading"
        :server-items-length="$userPagination.total"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="footerProps"
        :options.sync="options">
        <template v-slot:item.fullName="{ item }">
          {{ `${item.firstName} ${item.lastName}` }}
        </template>

        <template v-slot:item.id="{ item }">
          <copy-content :content="item.id" label="Click to copy user id">
            {{ item.id }}
          </copy-content>
        </template>

        <template v-slot:item.email="{ item }">
          <copy-content :content="item.email" label="Click to copy user email">
            {{ item.email }}
          </copy-content>
        </template>

        <template v-slot:item.role="{ item }">
          {{ getRole(item.role) }}
        </template>

        <template v-slot:item.isEmailVerified="{ item }">
          {{ item.isEmailVerified ? 'Yes' : 'No' }}
        </template>

        <template v-slot:header.warehouseAccessList>
          <span class="d-inline-block mr-1">Warehouses</span>
          <help-icon-tooltip> List of all warehouses this user is restricted to </help-icon-tooltip>
        </template>

        <template v-slot:item.warehouseAccessList="{ item }">
          <template v-if="item.warehouseAccessList?.length > 0">
            <v-btn
              text
              class="link px-0"
              x-small
              @click="warehouseDialog = { shouldShow: true, entity: item }">
              {{ getWarehouseList(item?.warehouseAccessList) }}
            </v-btn>
          </template>
          <span v-else>Unrestricted</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="row-actions">
            <!-- TODO: CJA: test with an Owner user and other users in their Org... -->
            <icon-tooltip-button
              icon-class="mr-2"
              size="large"
              @click="loginAsUser(item)"
              tooltip="Login as User"
              v-if="isLoginAsUserVisible(item)"
              icon="login"></icon-tooltip-button>

            <user-login-as-carrier-dialog v-if="isLoginAsCarrierVisible(item)" :user="item">
              <template v-slot:activator="{ on }">
                <icon-tooltip-button
                  icon-class="mr-2"
                  size="large"
                  tooltip="Login as Carrier"
                  v-on="on"
                  :disabled="!item.isEmailVerified"
                  icon="truck-delivery-outline"></icon-tooltip-button>
              </template>
            </user-login-as-carrier-dialog>

            <audit-log-entity-icon-button
              :item="item"
              timezone="UTC"
              :entity-name="`${item.firstName} ${item.lastName}`"
              :entity-type="novaCore.DatabaseEntities.User">
            </audit-log-entity-icon-button>

            <transfer-appointment-dialog :source-user="item" v-if="isLoginAsCarrierVisible(item)">
              <template v-slot:activator="{ on }">
                <icon-tooltip-button
                  icon-class="mr-2"
                  size="large"
                  tooltip="Transfer appointments"
                  v-on="on"
                  icon="transit-transfer">
                </icon-tooltip-button>
              </template>
            </transfer-appointment-dialog>

            <icon-tooltip-button
              icon-class="mr-2"
              size="large"
              @click="verifyEmail(item)"
              tooltip="Verify User Email"
              :disabled="item.isEmailVerified"
              v-if="novaCore.isInternalRole($me.role) && item.id !== $me.id"
              icon="mdi-email-check-outline"></icon-tooltip-button>

            <icon-tooltip-button
              v-if="canUserTakeAction(item)"
              icon-class="mr-2"
              @click="sendPasswordReset(item)"
              size="large"
              :loading="sendingPasswordResetId === item.id"
              tooltip="Send Password Reset"
              icon="key-change">
            </icon-tooltip-button>
            <span v-else class="d-inline-block mx-4"></span>

            <icon-tooltip-button
              icon-class="mr-2"
              size="large"
              @click="resendVerificationEmail(item)"
              :disabled="item.isEmailVerified"
              :loading="sendingVerificationEmailId === item.id"
              tooltip="Resend Verification Email"
              v-if="novaCore.isInternalRole($me.role) && item.id !== $me.id"
              icon="mdi-email-send-outline"></icon-tooltip-button>

            <edit-user-dialog :user="item">
              <template v-slot:activator="slotProps">
                <v-icon
                  :id="item.id"
                  size="large"
                  class="mr-2"
                  color="secondary"
                  v-if="novaCore.canUserUpdateUser($me, item.id, item) && canUserTakeAction(item)"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on"
                  >mdi-pencil
                </v-icon>
                <span v-else class="d-inline-block mx-4"></span>
              </template>
            </edit-user-dialog>

            <icon-tooltip-button
              size="large"
              @click="showDeleteUserConfirmation(item)"
              tooltip="Delete User"
              v-if="novaCore.canUserDeleteUser($me, item) && canUserTakeAction(item)"
              delete>
            </icon-tooltip-button>
            <span v-else class="d-inline-block mx-2"></span>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <list-warehouse-dialog
      @close="closeWarehouseDialog"
      v-if="warehouseDialog.entity"
      :warehouse-dialog="warehouseDialog"
      :warehouses="warehouseDialog.entity.warehouseAccessList"
      :label="`${warehouseDialog.entity.firstName} is assigned to the following locations:`"
      :header="`Warehouses ${warehouseDialog.entity.firstName} is assigned to`">
    </list-warehouse-dialog>

    <transfer-reserves-dialog
      v-if="deleting?.length > 0"
      :show-dialog="showTransferReserves"
      :deleted-user="deleting[0]"
      @confirm="confirmTransferReservesDialog"
      @close="cancelTransferReservesDialog">
    </transfer-reserves-dialog>

    <transfer-appointment-dialog
      v-if="deleting?.length > 0"
      :show-dialog="showTransferAppointments"
      :source-user="deleting[0]"
      cancel-label="Delete appointments"
      cancel-color="red"
      cancel-icon="delete-forever"
      @confirm="confirmTransferAppointmentsDialog"
      @close="cancelTransferAppointmentsDialog">
    </transfer-appointment-dialog>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import listWithWarehousesMixin from '@satellite/components/mixins/listWithWarehousesMixin';

/**
 * User List
 * @displayName User List
 */
export default {
  mixins: [dataListMixin, listWithWarehousesMixin],
  props: {
    orgId: {
      type: String,
      required: false,
      default: null
    },
    flat: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: 'Name', value: 'fullName', searchable: true, sortable: false },
        { text: 'Role', value: 'role', searchable: false },
        { text: 'Username / Email', value: 'email', searchable: true },
        { text: 'Warehouses', value: 'warehouseAccessList', searchable: true },
        { text: 'Email Verified?', value: 'isEmailVerified', searchable: false },
        { text: 'Actions', value: 'actions', sortable: false, align: 'end' }
      ],
      filters: {
        searchStr: '',
        showBaseUsers: false
      },
      deleting: [],
      sortBy: ['email'],
      searchFields: ['firstName', 'lastName', 'email'],
      sendingPasswordResetId: null,
      sendingVerificationEmailId: null,
      showTransferReserves: false,
      showTransferAppointments: false
    };
  },
  methods: {
    /**
     * Get User Data
     * @public
     */
    async getData() {
      try {
        this.loading = true;
        this.setInternalUserListSettings();
        await this.$store.dispatch('Users/getUsersWithWarehouses', this.getQuery());
      } finally {
        this.loading = false;
      }
    },
    setUserDeleted(user) {
      this.$store.commit('Users/setUserDeleted', user.id);
    },
    setInternalUserListSettings() {
      const isLoggedUserInternalUser = this.novaCore.isInternalUser(this.$me);

      if (isLoggedUserInternalUser) {
        // This will add to the /user list payload, but only the name field
        this.joins = ['org||name', 'company||name'];

        const orgColumn = { text: 'Org', value: 'org.name', searchable: true };
        const companyColumn = { text: 'Company', value: 'company.name', searchable: true };
        const userIdColumn = { text: 'ID', value: 'id', searchable: true, sortable: false };

        // Add the org column at the second position
        this.headers.splice(1, 0, orgColumn);

        if (this.filters.showBaseUsers) {
          // Add the company column only if the base users are visible, at the third position
          this.headers.splice(2, 0, companyColumn);

          // Set the search fields to look for the new fields, including the company
          this.searchFields = ['firstName', 'lastName', 'email', 'org.name', 'company.name'];
        } else {
          // Otherwise, remove the column from the table
          this.headers = this.headers.filter(h => h.text !== 'Company');

          // Set the search fields to look for the org name only
          this.searchFields = ['firstName', 'lastName', 'email', 'org.name'];
        }

        // Show user ID for internal users
        if (this.novaCore.isInternalUser(this.$me)) {
          this.headers.splice(1, 0, userIdColumn);
          this.searchFields.push('id');
        }

        // Make sure we don't have column duplicates
        this.headers = _.uniqBy(this.headers, h => h.text);
      }
    },
    isCarrierUser(user) {
      return this.novaCore.isCarrierUser(user);
    },
    isInternalUser(user) {
      return this.novaCore.isInternalUser(user);
    },
    async getAppointmentCount(user) {
      if (this.isCarrierUser(user)) {
        const response = await axios.get('/metrics/counts/appointment-count-for-carrier', {
          params: { carrierId: user.id }
        });

        if (response.data?.data) {
          return this.novaCore.sumArray(response.data.data, 'appointmentCount');
        }
      } else if (this.novaCore.isWarehouseUser(user)) {
        const response = await axios.get('/metrics/counts/reserve-count-for-user', {
          params: { userId: user.id }
        });

        if (response.data?.data) {
          return response.data.data.reserveCount || 0;
        }
      }

      return 0;
    },
    async buildDeleteWarningMessage(user) {
      const appointmentCount = await this.getAppointmentCount(user);
      let warningMessage = '';

      if (appointmentCount > 0) {
        let entity = `${this.isCarrierUser(user) ? 'Appointment' : 'Reserve'}`;

        if (appointmentCount > 1) {
          entity += 's';
        }

        warningMessage = `<p>You are also about to <strong>permanently delete:</strong></p>
          <p class="mb-1">
            <small>&#10060;</small> ${appointmentCount} ${entity}
          </p>
         `;

        if (this.isCarrierUser(user) && this.isInternalUser(this.$me)) {
          warningMessage += `<br /><small> * Since you are logged in as an internal user, you will be given a
                choice to transfer the appointments to another carrier contact after this modal</small>`;
        }
      }

      return { warningMessage, appointmentCount };
    },
    async showDeleteUserConfirmation(user) {
      const item = this.$users[this.$users.indexOf(user)];
      const { warningMessage, appointmentCount } = await this.buildDeleteWarningMessage(user);

      this.deleting.push(item);

      this.$confirm(warningMessage, {
        buttonTrueColor: 'red',
        width: 475,
        deleteConfirmation: true,
        buttonTrueText: 'YES, DELETE',
        icon: 'mdi-delete-forever',
        entityName: `${user.firstName} ${user.lastName}`,
        title: `Delete this ${this.isCarrierUser(user) ? 'Carrier Contact' : 'User'}?`,
        confirmationInputText: user.email
      })
        .then(confirmed => {
          if (confirmed) {
            if (appointmentCount > 0) {
              if (this.isCarrierUser(user) && this.isInternalUser(this.$me)) {
                this.showTransferAppointments = true;
              } else if (!this.isCarrierUser(user)) {
                this.showTransferReserves = true;
              } else {
                this.deleteUser(user);
              }
            } else {
              this.deleteUser(user);
            }
          } else {
            this.deleting = [];
          }
        })
        .catch(() => (this.deleting = []));
    },
    async deleteUser(user) {
      await this.$store.dispatch('Users/deleteUser', user);
      this.deleting = [];
    },
    async confirmTransferReservesDialog() {
      this.showTransferReserves = false;
      return this.deleteUser(this.deleting[0]);
    },
    async cancelTransferReservesDialog() {
      this.showTransferReserves = false;
      this.deleting = [];
    },
    async confirmTransferAppointmentsDialog() {
      this.showTransferAppointments = false;
      return this.deleteUser(this.deleting[0]);
    },
    async cancelTransferAppointmentsDialog() {
      this.showTransferAppointments = false;
      this.deleting = [];
    },
    getRole(role) {
      return this.novaCore.userRoleToText(role);
    },
    /**
     * Send password reset email
     * @public
     * @param user
     */
    async sendPasswordReset(user) {
      this.sendingPasswordResetId = user.id;
      await this.$store.dispatch('Users/sendPasswordReset', user).finally(() => {
        this.sendingPasswordResetId = null;
      });
    },
    getQuery() {
      let query = this.getQueryBase();

      if (!this.filters.showBaseUsers) {
        query.s.role = { $ne: this.novaCore.UserRole.BASE };
      }

      if (this.orgId) {
        query.s.orgId = { $eq: this.orgId };
      }

      return query;
    },
    async loginAsUser(user) {
      const userId = user.id;

      const res = await axios.post(`wormhole/login-as/${userId}`);
      const accessToken = res.data?.access_token;

      if (accessToken) {
        window.open(`wormhole/login-as/${accessToken}`, '_blank');
      }
    },
    async verifyEmail(user) {
      this.$confirm(`Are you sure you want to manually verify ${user.email}?`).then(confirmed => {
        if (confirmed) {
          this.$store.dispatch('Users/updateUser', {
            id: user.id,
            isEmailVerified: true
          });
        }
      });
    },
    async resendVerificationEmail(user) {
      this.$confirm(`Are you sure you want to send a verification email to ${user.email}?`).then(
        async confirmed => {
          if (confirmed) {
            this.sendingVerificationEmailId = user.id;

            const response = await this.$store
              .dispatch('Auth/sendVerificationEmail', user.email)
              .finally(() => {
                this.sendingVerificationEmailId = null;
              });

            if (response?.status === 200) {
              this.notify(`Verification email sent to ${user.email}!`);
            }
          }
        }
      );
    },
    canUserTakeAction(targetUser) {
      targetUser = {
        ...targetUser,
        warehouseAccessList: targetUser?.warehouseAccessList?.map(warehouse => warehouse?.id)
      };

      return this.novaCore.doesWarehouseAccessAllowUpdate(this.$me, targetUser);
    },
    isLoginAsUserVisible(user) {
      return (
        this.novaCore.isInternalRole(this.$me.role) &&
        user.id !== this.$me.id &&
        !this.novaCore.isCarrierUser(user)
      );
    },
    isLoginAsCarrierVisible(user) {
      return (
        this.novaCore.isInternalRole(this.$me.role) &&
        user.id !== this.$me.id &&
        this.novaCore.isCarrierUser(user)
      );
    }
  },
  mounted() {
    if (!this.novaCore.isInternalRole(this.$me.role)) {
      this.$eventHub.$on(['create-User', 'update-User'], this.getData);
      this.$eventHub.$on(['delete-User'], this.setUserDeleted);
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(['create-User', 'update-User']);
    this.$eventHub.$off(['delete-User']);
    this.$store.commit('Users/clearUsers');
  }
};
</script>
