import customFormsMixin from './customFormsMixin';

export default {
  mixins: [customFormsMixin],
  props: {
    /**
     * Appointment entity
     */
    appointment: {
      type: Object,
      required: true
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    compact: {
      type: Boolean,
      required: false,
      default: false
    },
    readOnly: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    warehouse() {
      // Implement on parent
      return null;
    },
    firstStatus() {
      return this.appointment.statusTimeline[this.requestedStatus]
        ? this.requestedStatus
        : this.scheduledStatus;
    },
    requestedStatus() {
      return this.novaCore.AppointmentStatus.Requested;
    },
    scheduledStatus() {
      return this.novaCore.AppointmentStatus.Scheduled;
    },
    arrivedStatus() {
      return this.novaCore.AppointmentStatus.Arrived;
    },
    inProgressStatus() {
      return this.novaCore.AppointmentStatus.InProgress;
    },
    completedStatus() {
      return this.novaCore.AppointmentStatus.Completed;
    },
    noShowStatus() {
      return this.novaCore.AppointmentStatus.NoShow;
    },
    cancelledStatus() {
      return this.novaCore.AppointmentStatus.Cancelled;
    },
    allChangeRules() {
      const optionalStatuses = [];
      if (this.firstStatus === this.novaCore.AppointmentStatus.Requested) {
        optionalStatuses.push(this.firstStatus);
      }
      if (this.useInProgressStatus) {
        optionalStatuses.push(this.novaCore.AppointmentStatus.InProgress);
      }
      return this.novaCore.getStatusChangeRules(optionalStatuses);
    },
    currentStatusChangeRules() {
      return this.allChangeRules[this.appointment.status];
    },
    shouldShowEtaValue() {
      return (
        Boolean(this.appointment.eta) &&
        this.appointment.status === this.novaCore.AppointmentStatus.Scheduled
      );
    },
    initialEtaCondition() {
      return (
        this.novaCore.getEtaCondition(
          this.appointment.start,
          this.appointment.eta,
          this.appointment.dock.warehouse.timezone
        ) ?? this.novaCore.EtaCondition.OnTime
      );
    },
    statuses() {
      let statuses = [];
      Object.entries(this.allChangeRules).map(entry => {
        let status = entry[1];
        status.name = entry[0];
        const isAppointmentHappyPath = this.getStatusChangeRules(
          this.appointment.status
        ).isHappyPath;
        const isStatusHappyPath = this.getStatusChangeRules(status.name).isHappyPath;
        if (!isAppointmentHappyPath) {
          status.show = Boolean(this.appointment.statusTimeline[status.name]);
        } else {
          status.show = Boolean(isStatusHappyPath);
        }

        statuses.push(status);
      });

      statuses = this.novaCore.sortBy(statuses, 'stepNumber');

      return statuses;
    },
    isCancelled() {
      return this.appointment.status === this.cancelledStatus;
    },
    arrivalTimeDiff() {
      let readableDuration = '';
      let arrivalTime = this.appointment.statusTimeline.Arrived;
      if (arrivalTime) {
        readableDuration = this.util.getReadableDuration(
          this.appointment.start,
          arrivalTime,
          this.timezone,
          ' LATE'
        );
      }

      return readableDuration;
    },
    inProgressTimeDiff() {
      let readableDuration = '';
      const arrivalTime = this.appointment.statusTimeline.Arrived;
      const inProgressTime = this.appointment.statusTimeline.InProgress;

      if (inProgressTime) {
        readableDuration = this.util.getReadableDuration(
          arrivalTime,
          inProgressTime,
          this.timezone,
          ' WAIT'
        );
      }

      return readableDuration;
    },
    completedTimeDiff() {
      let readableDuration = '';
      let completedTime = this.appointment.statusTimeline[this.completedStatus];
      if (completedTime) {
        readableDuration = this.util.getReadableDuration(
          this.appointment.statusTimeline[this.arrivedStatus],
          completedTime,
          this.timezone,
          ' DWELL'
        );
      }

      return readableDuration;
    },
    activeStatusCount() {
      return this.statuses.filter(status => status.show).length;
    },
    isNoShow() {
      return this.appointment.status === this.noShowStatus;
    },
    useInProgressStatus() {
      return (
        this.appointment.statusTimeline[this.inProgressStatus] ||
        this.warehouse?.settings?.statusSucceedingArrived ===
          this.novaCore.AppointmentStatus.InProgress
      );
    },
    lastVisibleStatus() {
      let lastStatus;
      this.statuses.forEach(status => {
        if (status.show) {
          lastStatus = status;
        }
      });
      return lastStatus;
    }
  },
  data() {
    return {
      timezone: this.appointment.dock.warehouse.timezone,
      loading: false,
      statusToEdit: '',
      showEditDialog: false,
      currentStep: -1,
      statusMap: {
        [this.novaCore.AppointmentStatus.Requested]: {
          description: 'Appointment has been requested.  To reject it, proceed with cancellation.',
          buttonLabel: 'Appointment Requested',
          icon: '',
          completedIcon: '',
          editable: false,
          compact: {
            buttonLabel: 'Requested',
            icon: ''
          }
        },
        [this.novaCore.AppointmentStatus.Scheduled]: {
          description: 'Appointment currently waiting for the carrier to arrive.',
          buttonLabel: 'Approve and Schedule',
          icon: '',
          completedIcon: '',
          editable: false,
          compact: {
            buttonLabel: 'Approve and Schedule',
            icon: ''
          }
        },
        [this.novaCore.AppointmentStatus.Arrived]: {
          description:
            'Carrier has arrived at the warehouse.  Proceed when loading/unloading is completed.',
          buttonLabel: 'Carrier Arrived',
          icon: '',
          completedIcon: '',
          editable: true,
          compact: {
            buttonLabel: 'Arrived',
            icon: ''
          }
        },
        [this.novaCore.AppointmentStatus.InProgress]: {
          description: this.useInProgressStatus
            ? 'Carrier has arrived at facility.  Proceed when loading/unloading starts.'
            : 'Carrier has arrived at the dock.  Proceed when loading/unloading is completed.',
          buttonLabel: 'Appointment In Progress',
          icon: '',
          completedIcon: '',
          editable: true,
          compact: {
            buttonLabel: 'In Progress',
            icon: ''
          }
        },
        [this.novaCore.AppointmentStatus.Completed]: {
          description: 'Loading/unloading ended and appointment has been completed.',
          buttonLabel: 'Appointment Completed',
          icon: '',
          completedIcon: '',
          editable: true,
          compact: {
            buttonLabel: 'Completed',
            icon: ''
          }
        },
        [this.novaCore.AppointmentStatus.NoShow]: {
          description: 'The carrier did not show for appointment.',
          buttonLabel: 'Report As No Show',
          icon: 'alert-octagon',
          completedIcon: 'alert-octagon',
          editable: false,
          compact: {
            buttonLabel: 'No Show',
            icon: ''
          }
        },
        [this.novaCore.AppointmentStatus.Cancelled]: {
          description: 'This appointment was cancelled',
          buttonLabel: '',
          completedIcon: 'close',
          editable: false,
          compact: {
            buttonLabel: 'Cancelled',
            icon: ''
          }
        }
      }
    };
  },
  methods: {
    ...customFormsMixin.methods,
    canEdit(status) {
      return (
        this.statusMap[status.name].editable &&
        this.appointment.statusTimeline[status.name] &&
        this.$rolePermissions.canUpdateAppointmentStatus &&
        !this.readOnly
      );
    },
    getButtonLabel(status) {
      let buttonLabel = '';
      if (status) {
        buttonLabel = this.compact ? status.compact.buttonLabel : status.buttonLabel;
      }

      return buttonLabel;
    },
    getStatusIcon(status) {
      const statusData = this.compact ? status.compact : status;
      return statusData.icon ? `mdi-${statusData.icon}` : '';
    },
    getNextHappyPathStatus(status) {
      let nextStatus = status.validNextStatuses.filter(
        nextStatus => this.statuses.find(status => nextStatus === status.name)?.isHappyPath
      );
      return nextStatus.length ? nextStatus[0] : '';
    },
    getNextNonHappyPathStatus(status) {
      const statusClone = this.novaCore.deepClone(status);
      if (status.validNextStatuses.includes(this.novaCore.AppointmentStatus.InProgress)) {
        if (
          !this.warehouse?.settings ||
          this.warehouse?.settings?.statusSucceedingArrived ===
            this.novaCore.AppointmentStatus.Completed
        ) {
          const inProgressIndex = statusClone.validNextStatuses.findIndex(
            status => status === this.novaCore.AppointmentStatus.InProgress
          );
          statusClone.validNextStatuses.splice(inProgressIndex, 1);
        }
      }
      let nextStatus = statusClone.validNextStatuses.filter(
        nextStatus =>
          nextStatus !== this.cancelledStatus &&
          !this.statuses.find(status => nextStatus === status.name)?.isHappyPath
      );
      return nextStatus.length ? nextStatus[0] : '';
    },
    getStatusChangeRules(status) {
      return this.allChangeRules[status];
    },
    /**
     * Update the appointment's status
     * @param {string} newStatus
     * @returns {Promise<void>}
     */
    async updateStatus(newStatus) {
      this.loading = true;

      this.activeTrigger = this.checkForTriggerOnStatus(newStatus);

      const data = {
        status: newStatus
      };

      if (this.hasActiveTrigger) {
        this.pendingStatusChange = newStatus;
        this.loading = false;
        return;
      }

      if (this.hasCustomFormsData) {
        data.customFormsData = this.customFormsData;
      }

      await axios
        .patch(`appointment/${this.appointment.id}`, data)
        .then(response => {
          if (response?.data?.data) {
            if (newStatus !== this.appointment.status) {
              this.$store.dispatch('Appointments/trackMixpanelEvent', {
                entryPoint: this.compact ? 'Quick Status Change' : null,
                appointment: this.appointment,
                change: `Status: ${newStatus}`
              });
            }
          }
          this.$emit('status-change');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async updateCustomFormsData(value, status) {
      for (const formData of value) {
        if (formData.id) {
          await axios.patch(`custom-forms/form-data/${formData.id}`, { value: formData.value });
        } else if (formData.value !== null) {
          await axios.post(`custom-forms/form-data`, { ...formData });
        }
      }

      this.notify(`Appointment data has been updated`, 'success');

      if (status) {
        this.pendingStatusChange = status;
        await this.updateStatus(status);
      }
      this.customFormsDataChange();
    },
    async submitStatusWithCustomFormsData(value) {
      this.customFormsData = value;
      await this.updateStatus(this.pendingStatusChange || this.appointment.status);
      this.customFormsDataChange();
    },
    formatDateTime(dateTime, format12, format24) {
      return this.novaCore.formatDateTimeWithMilitarySupport(
        dateTime,
        this.timezone,
        format12,
        this.$isMilitaryTimeEnabled(this.appointment.dock.warehouse),
        format24
      );
    },
    timeDiffInfo(statusName) {
      const greenWords = ['on time', 'early'];
      let textClass = 'error--text';

      let diff = '';

      switch (statusName) {
        case this.arrivedStatus: {
          diff = this.arrivalTimeDiff;
          break;
        }
        case this.inProgressStatus: {
          diff = this.inProgressTimeDiff;
          textClass = 'warning--text';
          break;
        }
        case this.completedStatus: {
          diff = this.completedTimeDiff;
          const appointmentDuration = momentjs
            .duration(momentjs(this.appointment.end).diff(momentjs(this.appointment.start)))
            .asMinutes();
          const dwellDuration = momentjs
            .duration(
              momentjs(this.appointment.statusTimeline[this.completedStatus]).diff(
                momentjs(this.appointment.statusTimeline[this.arrivedStatus])
              )
            )
            .asMinutes();
          if (dwellDuration <= appointmentDuration) {
            textClass = 'success--text';
          }
          break;
        }
      }

      if (greenWords.some(greenWord => diff.toLowerCase().indexOf(greenWord) !== -1)) {
        textClass = 'success--text';
      }

      return `<span class='${textClass}'>${diff}</span>`;
    },
    async undoLatestStatus() {
      this.loading = true;

      await axios
        .patch(`appointment/${this.appointment.id}/undo-latest-status`)
        .then(() => {
          this.$store.dispatch('Appointments/trackMixpanelEvent', {
            entryPoint: this.compact ? 'Quick Status Change' : null,
            appointment: this.appointment,
            change: 'Status: Undo'
          });
          this.$emit('status-change');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateCurrentStep() {
      this.currentStep = this.statuses.find(
        step => step.name === this.appointment.status
      ).stepNumber;
    },
    shouldShowNextHPButton(status) {
      return (
        this.getButtonLabel(this.statusMap[this.getNextHappyPathStatus(status)]) &&
        !status.isEndState
      );
    },
    openEditor(status) {
      this.showEditDialog = true;
      this.statusToEdit = status;
    },
    closeEditor() {
      this.showEditDialog = false;
      this.statusToEdit = '';
    },
    displayTime(status) {
      let statusesToDisplayTime = [
        this.arrivedStatus,
        this.inProgressStatus,
        this.completedStatus,
        this.cancelledStatus
      ];
      return statusesToDisplayTime.includes(status.name);
    },
    updateCondition(newCondition) {
      this.etaCondition = newCondition;
    },
    getCompletedIcon(status) {
      let icon = this.statusMap[status.name].completedIcon || 'check';
      if (this.novaCore.isRequested(this.appointment) && status.name === this.requestedStatus) {
        icon = 'clock-alert';
      } else if (
        this.novaCore.isInProgress(this.appointment) &&
        status.name === this.inProgressStatus
      ) {
        icon = 'timelapse';
      }
      return `mdi-${icon}`;
    }
  },
  mounted() {
    this.updateCurrentStep();
  },
  watch: {
    appointment() {
      this.updateCurrentStep();
    },
    initialEtaCondition: {
      handler(newVal) {
        this.etaCondition = newVal;
      },
      immediate: true
    }
  }
};
