import OrgsPage from './OrgsPage';
import { UserRole } from '@satellite/../nova/core';
import OrgPage from './OrgPage';

export default [
  {
    path: '/orgs',
    name: 'orgs',
    component: OrgsPage,
    props: true,
    meta: {
      title: 'Orgs',
      requiresAuth: true
    }
  },
  {
    path: '/org',
    name: 'org',
    props: false,
    component: OrgPage,
    meta: {
      title: 'My Organization',
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      minRole: UserRole.ADMIN
    }
  },
  {
    path: '/org/:orgId?/settings',
    name: 'org.settings',
    component: OrgPage,
    props: true,
    meta: {
      title: 'My Organization Settings',
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      minRole: UserRole.ADMIN
    }
  },
  {
    path: '/org/:orgId?/billing',
    name: 'org.billing',
    component: OrgPage,
    props: true,
    meta: {
      title: 'My Organization Billing',
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      minRole: UserRole.OWNER
    }
  },
  {
    path: '/org/:orgId?/loadtypes',
    name: 'org.loadtypes',
    component: OrgPage,
    props: true,
    meta: {
      title: 'My Organization Load Types',
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      minRole: UserRole.ADMIN
    }
  },
  {
    path: '/org/:orgId?/ssoconfig',
    name: 'org.ssoconfig',
    component: OrgPage,
    props: true,
    meta: {
      title: 'My Organization SSO Setup',
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      minRole: UserRole.ADMIN
    }
  },
  {
    path: '/org/:orgId?/warehouse-groups',
    name: 'org.warehouse-groups',
    component: OrgPage,
    props: true,
    meta: {
      title: 'My Organization Warehouse Groups Setup',
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      minRole: UserRole.ADMIN
    }
  }
];
