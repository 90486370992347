var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',{attrs:{"multiple":"","value":_vm.expansionValues}},[_vm._t("prepended-panel"),_vm._l((_vm.settings),function(group,settingGroup){return _c('v-expansion-panel',{key:settingGroup,class:{ disabled: _vm.readOnly }},[_c('v-expansion-panel-header',{attrs:{"ripple":""}},[_vm._v(" "+_vm._s(settingGroup)+" ")]),_c('v-divider'),_c('v-expansion-panel-content',[(settingGroup === _vm.novaCore.SettingGroups.GATE_MANAGEMENT)?_c('gate-management-checklist',{attrs:{"warehouse":_vm.entityParentObj}}):_vm._e(),_vm._l((group),function(setting,key,idx){return _c('div',{key:setting.label,staticClass:"setting-panel-content"},[(!idx && _vm.getSettingGroupDescriptionMeta(setting)?.description)?_c('div',{staticClass:"font-size-small mt-4 mb-6"},[_c('span',{staticClass:"d-inline-block mr-1"},[_vm._v(" "+_vm._s(_vm.insertEntity(_vm.getSettingGroupDescriptionMeta(setting)?.description))+" ")]),(_vm.getSettingGroupDescriptionMeta(setting)?.link)?_c('a',{staticClass:"font-size-x-small",attrs:{"target":"_blank","rel":"noopener","href":_vm.getSettingGroupDescriptionMeta(setting)?.link}},[_vm._v(" "+_vm._s(_vm.getSettingGroupDescriptionMeta(setting)?.linkText ?? 'Learn more')+" ")]):_vm._e()]):_vm._e(),(_vm.novaCore.areRequiredFieldsSet(_vm.entityParentObj, setting))?_c('v-tooltip',{attrs:{"top":"","disabled":_vm.novaCore.areSettingRequirementsMet(key, setting, _vm.localSettings) && !_vm.readOnly,"color":!_vm.readOnly ? 'error' : 'default'},scopedSlots:_vm._u([(!_vm.settingKeysToNotDynamicallyRender.includes(key))?{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({class:{ 'mt-6': idx }},'div',attrs,false),on),[(setting.isActive)?[_c('div',{staticClass:"setting-input is-relative",class:{
                    'my-4': !idx && !_vm.getSettingGroupDescriptionMeta(setting)?.description
                  }},[(!_vm.novaCore.isSettingHidden(key, setting, _vm.localSettings))?_c('label',{staticClass:"font-size-small d-flex v-label",attrs:{"for":_vm.getInputId(setting)}},[_c('strong',{staticClass:"d-block mr-2"},[_vm._v(_vm._s(_vm.insertEntity(setting.label)))]),(setting.description)?_c('help-icon-tooltip',{attrs:{"icon-style-class":"grey"}},[_vm._v(" "+_vm._s(_vm.insertEntity(setting.description))+" ")]):_vm._e(),(_vm.isInherited(key) && _vm.isInheritable(key))?_c('help-icon-tooltip',{attrs:{"icon-name":"mdi-alert-circle"}},[_vm._v(" "+_vm._s(_vm.getInheritedSettingMessage(key))+" ")]):_vm._e()],1):_vm._e(),(
                      setting.inputType === _vm.novaCore.SettingInputType.String &&
                      !_vm.novaCore.isSettingHidden(key, setting, _vm.localSettings)
                    )?_c('v-text-field',{staticClass:"mt-2 w-95",attrs:{"id":_vm.getInputId(setting),"disabled":!_vm.novaCore.areSettingRequirementsMet(key, setting, _vm.localSettings) || _vm.readOnly,"placeholder":setting.placeholder ?? setting.defaultValue,"hint":setting.note,"outlined":"","clearable":"","hide-details":!setting.note,"persistent-hint":"","persistent-placeholder":""},scopedSlots:_vm._u([{key:"message",fn:function({ message, key }){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.insertEntity(message))}})]}}],null,true),model:{value:(_vm.localSettings[key]),callback:function ($$v) {_vm.$set(_vm.localSettings, key, $$v)},expression:"localSettings[key]"}}):_vm._e(),(
                      setting.inputType === _vm.novaCore.SettingInputType.LongString &&
                      !_vm.novaCore.isSettingHidden(key, setting, _vm.localSettings)
                    )?_c('v-textarea',{staticClass:"mt-2 w-95",attrs:{"id":_vm.getInputId(setting),"disabled":!_vm.novaCore.areSettingRequirementsMet(key, setting, _vm.localSettings) || _vm.readOnly,"placeholder":setting.placeholder ?? setting.defaultValue,"hint":setting.note,"outlined":"","clearable":"","hide-details":!setting.note,"persistent-hint":"","persistent-placeholder":""},scopedSlots:_vm._u([{key:"message",fn:function({ message, key }){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.insertEntity(message))}})]}}],null,true),model:{value:(_vm.localSettings[key]),callback:function ($$v) {_vm.$set(_vm.localSettings, key, $$v)},expression:"localSettings[key]"}}):_vm._e(),(
                      setting.inputType === _vm.novaCore.SettingInputType.Bool &&
                      !_vm.novaCore.isSettingHidden(key, setting, _vm.localSettings)
                    )?_c('v-select',{staticClass:"mt-2 w-95",attrs:{"id":_vm.getInputId(setting),"disabled":!_vm.novaCore.areSettingRequirementsMet(key, setting, _vm.localSettings) || _vm.readOnly,"outlined":"","hide-details":!setting.note,"persistent-hint":"","items":_vm.getBoolOptions(key, setting),"item-text":"label","item-value":"value","hint":setting.note},scopedSlots:_vm._u([{key:"message",fn:function({ message, key }){return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}},{key:"selection",fn:function({ item, index }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.label)}})]}},{key:"item",fn:function({ item, on }){return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-content',[_c('v-list-item-title',{class:{
                              'font-weight-medium primary--text':
                                item.value === _vm.localSettings[key]
                            },domProps:{"innerHTML":_vm._s(item.label)}}),(item.example)?_c('v-list-item-subtitle',{staticClass:"text--black font-size-x-small"},[_vm._v(" "+_vm._s(item.example)+" ")]):_vm._e()],1)],1)]}}],null,true),model:{value:(_vm.localSettings[key]),callback:function ($$v) {_vm.$set(_vm.localSettings, key, $$v)},expression:"localSettings[key]"}}):_vm._e(),(
                      setting.inputType === _vm.novaCore.SettingInputType.DropDown &&
                      !_vm.novaCore.isSettingHidden(key, setting, _vm.localSettings)
                    )?_c('v-select',{staticClass:"mt-2 w-95",attrs:{"id":_vm.getInputId(setting),"disabled":!_vm.novaCore.areSettingRequirementsMet(key, setting, _vm.localSettings) || _vm.readOnly,"outlined":"","hide-details":!setting.note,"persistent-hint":"","items":_vm.getDropDownOptions(key, setting.dropDownOptions),"item-text":"label","item-value":"value","hint":setting.note},scopedSlots:_vm._u([{key:"message",fn:function({ message, key }){return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}},{key:"selection",fn:function({ item, index }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.label)}})]}},{key:"item",fn:function({ item, on }){return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-content',[_c('v-list-item-title',{class:{
                              'font-weight-medium primary--text':
                                item.value === _vm.localSettings[key]
                            },domProps:{"innerHTML":_vm._s(item.label)}})],1)],1)]}}],null,true),model:{value:(_vm.localSettings[key]),callback:function ($$v) {_vm.$set(_vm.localSettings, key, $$v)},expression:"localSettings[key]"}}):_vm._e(),(
                      setting.inputType === _vm.novaCore.SettingInputType.ClockArray &&
                      !_vm.novaCore.isSettingHidden(key, setting, _vm.localSettings)
                    )?_c('clock-selector',{staticClass:"w-95",attrs:{"id":_vm.getInputId(setting),"disabled":!_vm.novaCore.areSettingRequirementsMet(key, setting, _vm.localSettings) || _vm.readOnly,"outlined":"","hide-details":!setting.note,"persistent-hint":"","description":setting.note},model:{value:(_vm.localSettings[key]),callback:function ($$v) {_vm.$set(_vm.localSettings, key, $$v)},expression:"localSettings[key]"}}):_vm._e(),(setting.inputType === _vm.novaCore.SettingInputType.Document)?[_c('div',{staticClass:"d-flex align-center mt-2"},[_c('document-upload-button',{staticClass:"flex-grow-0",attrs:{"read-only":_vm.readOnly,"crop-shape":setting.documentOptions?.cropShape,"aspectRatio":setting.documentOptions?.aspectRatio,"show-crop-result":setting.documentOptions?.showCropResult,"modal-header-text":setting.documentOptions?.modalHeaderText,"allow-cropping":"","max-size":setting.maxFileSize ?? 1024,"conversion-unit":setting.fileSizeUnit ?? _vm.novaCore.FileUnits.MB,"allowed-mime-types":setting.allowedMimeTypes},model:{value:(_vm.localSettings[key]),callback:function ($$v) {_vm.$set(_vm.localSettings, key, $$v)},expression:"localSettings[key]"}})],1),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(_vm._s(setting.description))])])])])]:_vm._e(),(
                      !_vm.novaCore.isSettingHidden(key, setting, _vm.localSettings) &&
                      _vm.novaCore.shouldDisplayError(key, setting, _vm.localSettings)
                    )?[_c('v-tooltip',{attrs:{"right":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"setting-error font-size-base",attrs:{"color":"error"}},on),[_vm._v("mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(setting.errorMessage))])])]:_vm._e()],2)]:_vm._e()],2)]}}:null],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.makeFieldTooltipText(key, setting))}})]):_vm._e()],1)}),(
          _vm.isVisibleMutedAppointmentNotifications(_vm.localSettings) &&
          settingGroup === _vm.novaCore.SettingGroups.APPOINTMENT_NOTIFICATIONS
        )?_c('email-notifications-list',{staticClass:"mt-8",attrs:{"entity-settings":_vm.localSettings},on:{"settingsUpdated":_vm.setSettings}}):_vm._e(),(
          settingGroup === _vm.novaCore.SettingGroups.CUSTOM_APPPOINTMENTS_GRID
        )?_c('grid-tile-config-editor',{attrs:{"input-enabled":_vm.localSettings.useNewGridTiles,"input-config":_vm.localSettings.gridTileConfig,"warehouse":_vm.entityParentObj},on:{"configUpdated":config => (_vm.localSettings.gridTileConfig = config),"enabledUpdated":val => (_vm.localSettings.useNewGridTiles = val)}}):_vm._e()],2)],1)}),_vm._t("append-panel")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }