// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  async createSignup({}, feedback) {
    return await axios.post(`feedback`, {
      text: feedback,
      tags: ['BetaSignup']
    });
  }
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  name: 'Beta',
  state,
  getters,
  actions,
  mutations
};
