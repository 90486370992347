<template>
  <div style="min-height: 500px">
    <v-tabs v-model="tab" grow class="mb-4 non-active-tab-underline">
      <v-tab key="status">Status & Details</v-tab>
      <v-tab key="claims_support" :disabled="claimSupportTriggers.length === 0">
        Claims Support
      </v-tab>
      <v-tab key="audit_log">Audit Log</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="details">
        <appointment-details-status v-if="tab === 0" v-bind="$props" />
      </v-tab-item>
      <v-tab-item key="claims_support">
        <appointment-details-claims v-if="tab === 1" v-bind="$props" />
      </v-tab-item>
      <v-tab-item key="audit_log" @click="trackApptDetailsMixpanelEvent">
        <appointment-details-audit-log v-if="tab === 2" v-bind="$props" />
      </v-tab-item>
    </v-tabs-items>

    <custom-forms-flow-dialog
      :external-activator="true"
      :warehouse="warehouse"
      :show-dialog="hasActiveTrigger"
      :object-id="appointment.id"
      :trigger="activeTrigger"
      @close="cleanUpCustomFormsData"
      @update="updateCustomFormsData"
      @create="value => createBulkCustomFormsData(value, warehouse.id)"></custom-forms-flow-dialog>
  </div>
</template>

<script>
import AppointmentDetailsBase from '@/modules/appointments/components/details/AppointmentDetailsBase.vue';

/**
 * @displayName Appointment Details
 */
export default {
  extends: AppointmentDetailsBase,
  data() {
    return {
      tab: 0
    };
  },
  watch: {
    tab() {
      this.$emit('change-active-tab', this.tab);
    }
  },
  destroyed() {
    this.$eventHub.$off('action-field-submitted', this.handleActionFieldTriggered);
  },
  beforeMount() {
    this.$eventHub.$on('action-field-submitted', this.handleActionFieldTriggered);
    this.trackApptDetailsMixpanelEvent();
  }
};
</script>
