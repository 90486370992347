<template functional>
  <span class="quick-reserve-wrapper">
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          x-small
          icon
          :disabled="props.loading"
          @click="
            listeners['create-reserve']({
              dockId: props.dockId,
              start: props.start
            })
          ">
          <v-icon x-small>mdi-lightning-bolt</v-icon>
        </v-btn>
      </template>
      <span>Creates a Reserve with a duration of {{ props.intervalMinutes }} minutes </span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    start: {
      type: String,
      required: true
    },
    dockId: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    intervalMinutes: {
      type: Number,
      required: true
    }
  }
};
</script>
