<template>
  <dialog
    ref="contextMenu"
    :style="getPositionStyle"
    class="context-menu d-flex flex-column"
    @click.stop
    @mousedown.stop>
    <v-btn icon absolute right @click.stop="$emit('close')">
      <v-icon>mdi-close-circle</v-icon>
    </v-btn>
    <div class="pa-4 black--text">
      <span class="d-flex flex-row align-center">
        <v-icon color="black" x-small>mdi-truck</v-icon>
        <span class="d-inline-block ml-2">{{ appointment.companyName }}</span>
      </span>
      <span class="d-block">
        <strong>{{ appointment.refNumLabel }}</strong>
        : {{ appointment.refNumber }}
      </span>
    </div>
    <v-divider></v-divider>
    <div class="pt-4 pr-4 pb-1 pl-4 black--text">
      <span class="sub-title text-left d-block">Change Status To</span>
    </div>
    <status-manager
      @status-change="$emit('close')"
      :appointment="appointment"
      mixpanel-entry-point="Quick Status Change"
      :warehouse-triggers="$selectedWarehouseTriggers"
      dense
      compact
      class="mb-2"></status-manager>
    <v-divider></v-divider>
    <template v-if="canCancel || isCancelled">
      <v-btn
        v-if="!isCancelled && $rolePermissions.canUpdateAppointment"
        elevation="0"
        class="text__error font-weight-bold my-2"
        color="white"
        @click="confirmCancel"
        :disabled="appointment.status === novaCore.AppointmentStatus.Cancelled">
        <v-icon class="mr-2 colored">{{ cancelButtonIcon }}</v-icon>
        {{ cancelAppointmentLabel }}
      </v-btn>
    </template>
    <cancel-appointment-dialog
      ref="cancelAppointment"
      @confirm="$emit('confirm')"
      mixpanel-entry-point="Quick Status Change"
      :external-activator="true"
      :show-dialog="showConfirmDialog"
      :appointment="appointment"
      @close="showConfirmDialog = false"></cancel-appointment-dialog>
  </dialog>
</template>

<script>
import appointmentMixin from '@/components/mixins/appointmentMixin';

/**
 * @displayName Event Context Menu
 */
export default {
  name: 'EventContextMenu',
  mixins: [appointmentMixin],
  props: {
    /**
     * Event object named 'appointment' to work with appointment mixin
     */
    appointment: {
      type: Object,
      required: true
    },
    nativeEvent: {
      type: PointerEvent,
      required: false
    }
  },
  data() {
    return {
      showConfirmDialog: false
    };
  },
  computed: {
    /*
     * Returns true if the user clicked on the last third of the screen
     */
    isEventNearBottomOfScreen() {
      const clickYPos = this.nativeEvent?.pageY;
      const windowHeight = window.innerHeight || 600;
      const lastThirdWindow = ~~(windowHeight / 1.3);

      return clickYPos && clickYPos > lastThirdWindow;
    },
    /*
     * Returns true if the user clicked on the last third of the screen
     */
    isEventNearRightOfScreen() {
      const clickXPos = this.nativeEvent?.pageX;
      const windowWidth = window.innerWidth || 800;
      const lastThirdWindow = ~~(windowWidth / 1.3);

      return clickXPos && clickXPos > lastThirdWindow;
    },
    /*
     * Draws the context menu attaching from the bottom or the top of the event based where the user clicked
     */
    getPositionStyle() {
      const selfHeight = this.$refs.contextMenu?.clientHeight || 240;
      const selfWidth = this.$refs.contextMenu?.clientWidth || 230;

      const style = {
        top: `${this.nativeEvent.offsetY}px`,
        left: `${this.nativeEvent.offsetX}px`
      };

      if (this.isEventNearBottomOfScreen) {
        style.top = `${this.nativeEvent.offsetY - selfHeight}px`;
      }

      if (this.isEventNearRightOfScreen) {
        style.left = `${this.nativeEvent.offsetX - selfWidth}px`;
      }

      return style;
    }
  }
};
</script>
