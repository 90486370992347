<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <div class="carrier-contact-data-table-wrapper">
    <v-data-table
      data-testid="carrier-contacts-data-table"
      :class="{ 'data-table-no-results': isEmptyState }"
      :headers="headers"
      :loading="carrierContacts.isLoadingRef.value"
      :items="carrierContacts.carrierContactsRef.value"
      :items-per-page="carrierContacts.paramsRef.itemsPerPage"
      :sort-by.sync="carrierContacts.paramsRef.sortBy"
      :sort-desc.sync="carrierContacts.paramsRef.sortDesc"
      :server-items-length="carrierContacts.paginationRef.value.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50] }"
      @update:options="handleOptionsUpdate">
      <template #top>
        <div class="d-flex justify-space-between align-center data-table-top">
          <div class="text-field-wrapper d-flex align-center">
            <v-text-field
              flat
              solo
              dense
              clearable
              single-line
              hide-details
              v-model="searchStr"
              class="text-field"
              label="Find in this table..."
              prepend-icon="mdi-magnify"
              :loading="carrierContacts.isLoadingRef.value" />
            <p class="mb-0">
              <span class="font-weight-medium">
                {{ totalCarrierContacts }}
              </span>
              <span>{{ totalCarrierContacts === 1 ? 'result' : 'results' }}</span>
            </p>
          </div>
          <div class="switch-wrapper d-flex align-center justify-end">
            <v-switch
              dense
              ripple
              hide-details
              label="Favorites only"
              class="mt-0"
              v-model="favoritesOnly"
              data-testid="carrier-contacts-favorite-only-button" />
          </div>
        </div>
      </template>
      <template v-for="header in headers" #[`item.${header.value}`]="{ item }">
        <span :key="`${header.value}-if`" v-if="getItemValue(item, header.value)">
          {{ getItemValue(item, header.value) }}
        </span>
        <span :key="`${header.value}-else`" v-else class="item-no-value">None</span>
      </template>
      <template #item.emailCCs="{ item }">
        <carrier-email-c-cs-column :carrier-contact="item" />
      </template>
      <template #item.favoriteWarehouses="{ item }">
        <carrier-contact-warehouse-dialog
          :favoriteWarehouses="item.favoriteWarehouses"
          :isOrgFavorite="item.isOrgFavorite"
          :isFavorite="item.isFavorite"
          :carrier="item.user"
          :company="item.company" />
      </template>
      <template #item.actions="{ item }">
        <div class="d-flex flex-row justify-end">
          <carrier-contact-manage-dialog :carrier-contact="item" />
          <carrier-contact-actions-menu :carrier-contact="item" />
        </div>
      </template>
    </v-data-table>
    <div
      data-testid="carrier-contacts-data-table-empty-overlay"
      class="empty-state-overlay"
      v-if="isEmptyState">
      <v-icon size="40px">mdi-truck-outline</v-icon>
      <div class="text">
        <p class="font-weight-medium header">You don't have any contacts on your Org’s list</p>
        <p class="subheader">Start searching to add carrier contacts</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import zip from 'lodash/zip';

import useDebouncedInput from '@/composables/useDebouncedInput';
import useCarrierContacts from '@/composables/useCarrierContacts';

const headers = [
  { text: 'Email', value: 'user.email', searchable: true, sortable: true },
  { text: 'Name', value: 'user.fullName', searchable: true, sortable: true },
  { text: 'Company', value: 'company.name', searchable: true, sortable: true },
  { text: 'SCAC', value: 'company.scac', searchable: false, sortable: false },
  { text: 'Phone Number', value: 'user.phone', searchable: true, sortable: false },
  { text: 'Email subscribers', value: 'emailCCs', searchable: false, sortable: false },
  {
    text: 'Favorite for',
    value: 'favoriteWarehouses',
    searchable: false,
    sortable: false,
    width: 350
  },
  { text: 'Actions', value: 'actions', align: 'end', searchable: false, sortable: false }
];

function getItemValue(item, key) {
  const value = _.get(item, key);
  const hasValue = Array.isArray(value) ? (value?.length || 0) > 0 : !!value;
  return hasValue ? value : undefined;
}

function optionsToParams(options = {}) {
  const { sortBy, sortDesc, itemsPerPage, page } = options;
  const sort = zip(sortBy, sortDesc).flatMap(([by, desc]) => {
    const descStr = desc ? 'DESC' : 'ASC';
    if (by == 'user.fullName') {
      return [
        ['user.firstName', descStr],
        ['user.lastName', descStr]
      ];
    }
    return [[by, descStr]];
  });
  return { sort, page, limit: itemsPerPage };
}

export default defineComponent({
  name: 'CarrierDataTable',
  props: {},
  setup() {
    const carrierContacts = useCarrierContacts({ prefetch: true });
    const totalCarrierContacts = computed(() => carrierContacts.paginationRef.value.total || 0);
    const { value: searchStr } = useDebouncedInput({
      initialValue: carrierContacts.paramsRef.value.searchStr,
      onChange: value => {
        carrierContacts.paramsRef.value = { ...carrierContacts.paramsRef.value, searchStr: value };
        carrierContacts.fetch();
      }
    });
    const favoritesOnly = computed({
      get() {
        return carrierContacts.paramsRef.value.favoritesOnly;
      },
      set(favoritesOnly) {
        carrierContacts.paramsRef.value = { ...carrierContacts.paramsRef.value, favoritesOnly };
        carrierContacts.fetch();
      }
    });
    const isEmptyState = computed(() => {
      const noContacts = (carrierContacts.carrierContactsRef.value?.length || 0) === 0;
      const isLoading = carrierContacts.isLoadingRef.value;
      const hasSearch = (searchStr.value || '') !== '';
      return noContacts && !isLoading && !hasSearch;
    });
    const handleOptionsUpdate = options => {
      carrierContacts.paramsRef.value = {
        ...carrierContacts.paramsRef.value,
        ...optionsToParams(options)
      };
      carrierContacts.fetch();
    };

    return {
      headers,
      carrierContacts,
      getItemValue,
      handleOptionsUpdate,
      searchStr,
      totalCarrierContacts,
      favoritesOnly,
      isEmptyState
    };
  }
});
</script>

<style scoped lang="scss">
.item-no-value {
  color: $color-text-disabled;
}

.subscriber-email-wrapper {
  display: inline-block;
}

.data-table-top {
  padding: 16px;
  border-bottom: 1px solid $color-line-border;
}

.text-field-wrapper {
  color: $m-color-text-secondary;
  flex: 1;
  gap: 12px;

  .text-field {
    width: 50%;
    max-width: 50%;
    border-radius: 4px;
    border: 1px solid $color-line-border;
    padding: 4px 12px;

    &:focus,
    &:focus-within {
      border-color: $color-primary-60;
    }
  }
}

.switch-wrapper {
  flex: 1;
}

.font-weight-medium {
  font-weight: $m-font-weight-medium !important;
}

.v-data-table {
  border: 1px solid $color-line-border;
  :deep(.v-data-table__wrapper) > table {
    thead > tr > th {
      padding: 10px 16px;
    }
    tbody > tr > td {
      border-bottom: thin solid $color-line-border !important;
    }
  }
}

.carrier-contact-data-table-wrapper {
  position: relative;
}

.data-table-no-results {
  opacity: 0.5;
  min-height: 300px;
}

.empty-state-overlay {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  gap: 16px;

  p {
    margin: 0;
    text-align: center;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }

    .header {
      font-size: 16px;
    }

    .subheader {
      font-weight: 400;
      color: $m-color-text-secondary;
    }
  }

  i.v-icon {
    display: block;
    background-color: $m-color-neutral-20;
    border-radius: 50px;
    padding: 25px;
  }
}
</style>
