import { isMobile } from '@satellite/plugins/util';
import { AppointmentType } from '@satellite/../nova/core';

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
  async handleSocketEvent({ dispatch, rootGetters }, payload) {
    if (isMobile() && payload.data.type === AppointmentType.Reserve) {
      return;
    }
    let mutation = 'insertEvent';
    if (payload.action === 'delete' || !payload.data.isActive) {
      mutation = 'removeEvent';
    }

    const selectedEvent = rootGetters['Calendar/selectedEvent'] ?? {};

    const hasDebounce = Boolean(payload.data.debounce.count);
    const debouncedIds = payload.data.debounce?.ids || [];
    const debouncedActiveEventId = debouncedIds.find(id => selectedEvent.id === id);

    if (!hasDebounce || debouncedActiveEventId) {
      const selectedDockIds = rootGetters['Calendar/selectedDockIds'] ?? [];
      if (selectedDockIds.includes(payload.data.dockId) || selectedEvent.id === payload.data.id) {
        await dispatch(
          'Calendar/refreshEventByAppointmentId',
          {
            id: debouncedActiveEventId ?? payload.data.id,
            action: payload.action,
            mutation: mutation
          },
          { root: true }
        );
      }
    } else {
      dispatch('Calendar/getEvents', {}, { root: true });
    }
  }
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  name: 'Appointments',
  state,
  getters,
  actions,
  mutations
};
