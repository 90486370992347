<template>
  <v-expansion-panel class="asset-item-panel" :data-testid="`asset-visit-panel-${visit.id}`">
    <v-expansion-panel-header>
      <div class="asset-item-header">
        <div class="asset-item-title font-weight-bold mb-2">
          <slot name="title" :visit="visit"></slot>
        </div>
        <span class="font-weight-regular">
          <span :data-asset-company-id="visit.company?.id ?? visit.companyHint">
            {{ visit.company?.name ?? visit.companyHint }} -
          </span>
          arrived at
          {{ getArrivalTime(visit.createDateTime) }}
        </span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <asset-visit-form-data
        :class="{ 'mb-8': showActions }"
        :asset-visit="visit"
        :company="visit.company || { name: visit.companyHint }"></asset-visit-form-data>

      <template v-if="visit.appointmentId">
        <router-link :to="{ name: 'appointments', query: { appointmentId: visit.appointmentId } }">
          View appointment
        </router-link>
        <outline-button
          x-small
          class="ml-4 error--text"
          :data-testid="`asset-visit-panel-unlink-button-${visit.id}`"
          @click="handleUnlinkConfirmDialog(visit.id, visit.appointmentId)">
          Unlink From Appointment
        </outline-button>
      </template>

      <div class="asset-actions" v-if="showActions">
        <create-appointment-dialog
          :disable-activator="lockActions"
          button-label="Create appointment"
          :asset-visit="visit"
          :context="{ selectedWarehouse: warehouse }"
          @scheduled="appointment => handleNewAppointment(appointment, visit.id)">
          <template #activator="slotProps">
            <primary-button
              :data-testid="`asset-visit-panel-create-appointment-button-${visit.id}`"
              :disabled="slotProps.isButtonDisabled"
              v-on="slotProps.on"
              v-bind="[slotProps.attrs]">
              Create appointment
            </primary-button>
          </template>
        </create-appointment-dialog>

        <outline-button
          :data-testid="`asset-visit-panel-link-button-${visit.id}`"
          :disabled="lockActions"
          @click="$emit('show-appointment-list-dialog', visit.id)">
          Link To Appointment
        </outline-button>

        <v-btn
          :data-testid="`asset-visit-panel-reject-button-${visit.id}`"
          :loading="rejectingLoader"
          plain
          text
          @click="showRejectConfirm = true"
          :disabled="lockActions">
          Reject Load
        </v-btn>
      </div>

      <confirm
        :should-show="showRejectConfirm"
        is-manual-mode
        persistent
        v-if="showRejectConfirm"
        @result="handleRejectResult"
        title="Reject load?"
        :width="650"
        button-true-color="primary"
        color="warning">
        <template #message>
          <div>
            <v-alert text prominent border="left" color="warning" class="my-7">
              <v-icon color="#955800" class="mr-2">mdi-alert</v-icon>
              <span class="text--primary">This action cannot be undone</span>
            </v-alert>
          </div>
        </template>

        <template #true-action="{ choose }">
          <v-btn color="primary" @click="choose(true)" :loading="rejectingLoader">
            Reject load
          </v-btn>
        </template>
      </confirm>

      <confirm
        :should-show="showUnlinkConfirm"
        is-manual-mode
        persistent
        v-if="showUnlinkConfirm"
        @result="handleUnlinkResult"
        title="Unlink from appointment?"
        :width="650"
        button-true-color="primary"
        color="warning">
        <template #message>
          <div>
            <v-alert text prominent border="left" color="warning" class="my-7">
              <v-icon color="#955800" class="mr-2">mdi-alert</v-icon>
              <span class="text--primary">This action cannot be undone</span>
            </v-alert>
          </div>
        </template>

        <template #true-action="{ choose }">
          <v-btn color="primary" @click="choose(true)" :loading="unlinkingLoader">
            Unlink from appointment
          </v-btn>
        </template>
      </confirm>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { formatDateTimeWithMilitarySupport, LuxonDateTimeFormats } from '@satellite/../nova/core';
import { ref } from 'vue';
import { useRouter, useStore, useNotify } from '@/composables';
import AssetVisitFormData from '@/components/elements/AssetVisitFormData.vue';

export default {
  components: { AssetVisitFormData },
  props: {
    visit: {
      type: Object,
      required: true
    },
    warehouse: {
      type: Object,
      required: true
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['updated', 'show-appointment-list-dialog'],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const notify = useNotify();
    const lockActions = ref(false);
    const rejectingLoader = ref(false);
    const unlinkingLoader = ref(false);
    const unlinkData = ref(null);

    const showUnlinkConfirm = ref(false);
    const showRejectConfirm = ref(false);

    async function handleNewAppointment(appointment, visitId) {
      const apptWithUser = (
        await axios.get(`appointment/${appointment?.id}`, {
          params: { select: 'id', join: 'user||companyId' }
        })
      )?.data?.data;
      const assetVisit = await axios.post('/checkin/link-unplanned-checkin-to-appointment', {
        assetVisitId: visitId,
        appointmentId: appointment?.id,
        companyId: apptWithUser?.user?.companyId,
        isPlanned: false
      });
      await router.replace(`/appointments?appointmentId=${appointment?.id}`);
      context.emit('updated', assetVisit?.data?.data);
    }

    async function handleRejectResult(result) {
      if (result) {
        await rejectLoad();
      }
      showRejectConfirm.value = false;
    }

    async function rejectLoad() {
      lockActions.value = true;
      rejectingLoader.value = true;
      try {
        const response = await axios.post('/asset-visit/event', {
          assetVisitId: props.visit.id,
          eventType: 'Canceled'
        });
        context.emit('updated', response?.data?.data);
      } finally {
        lockActions.value = false;
        rejectingLoader.value = false;
      }
    }

    function getArrivalTime(timestamp) {
      return formatDateTimeWithMilitarySupport(
        timestamp,
        props.warehouse.timezone,
        LuxonDateTimeFormats.MonthDayYearSlashedTimeAMPM,
        store.getters['Settings/isMilitaryTimeEnabled'](props.warehouse),
        LuxonDateTimeFormats.MonthDayYearSlashedTime24
      );
    }

    function handleUnlinkConfirmDialog(visitId, appointmentId) {
      unlinkData.value = { visitId, appointmentId };
      showUnlinkConfirm.value = true;
    }

    async function handleUnlinkResult(result) {
      if (result) {
        await unlinkCheckinFromAppointment(
          unlinkData.value.visitId,
          unlinkData.value.appointmentId
        );
      }
      showUnlinkConfirm.value = false;
      unlinkData.value = null;
    }

    async function unlinkCheckinFromAppointment(visitId, appointmentId) {
      unlinkingLoader.value = true;
      try {
        await axios.post('/checkin/unlink-checkin-from-appointment', {
          assetVisitId: visitId,
          appointmentId
        });
        notify('Asset visit has been unlinked from the appointment');
      } finally {
        unlinkingLoader.value = false;
      }
    }

    return {
      handleNewAppointment,
      getArrivalTime,
      lockActions,
      rejectingLoader,
      showRejectConfirm,
      handleRejectResult,
      unlinkingLoader,
      unlinkData,
      handleUnlinkConfirmDialog,
      showUnlinkConfirm,
      handleUnlinkResult
    };
  }
};
</script>

<style scoped lang="scss">
.asset-item-header {
  flex-direction: column;
}

.asset-item-title {
  font-size: 16px;
}

.asset-item-panel {
  border: 1px solid $color-line-border;
  border-radius: 0;
}

.asset-item-panel,
.v-expansion-panel-header {
  &:before,
  &:after {
    display: none;
  }
}

.asset-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

:deep .v-expansion-panel-content {
  border-top: 1px solid $color-line-border;
  padding-top: 12px;
}
</style>
