<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    header="Edit Organization"
    loading-message="Updating Organization..."
    @close="close"
    @confirm="$refs.editOrgForm.submit()">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <secondary-button
        before-icon="login"
        large
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        class="mr-2">
        Edit Org
      </secondary-button>
    </template>
    <template slot="body">
      <edit-org-form @close="close" ref="editOrgForm"></edit-org-form>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit org form
 * @displayName EditOrgDialog
 */
export default {
  mixins: [dialogMixin]
};
</script>
