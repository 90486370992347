<template>
  <dialog-base
    v-bind="$props"
    :key="renderKey"
    :header="dialogHeader"
    @close="close"
    width="510px"
    has-dialog-actions
    max-width="90%">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <primary-button
        before-icon="pencil"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2">
        Edit Time
      </primary-button>
    </template>
    <template v-slot:body>
      <v-alert :type="alertType" class="mb-0" shaped v-if="alertMessage">
        {{ alertMessage }}
      </v-alert>
      <status-timeline-edit-form
        :mixpanel-entry-point="mixpanelEntryPoint"
        :appointment="appointment"
        @close="close"
        :status-to-edit="statusToEdit"></status-timeline-edit-form>
    </template>
  </dialog-base>
</template>

<script>
import StatusTimelineEditDialogBase from '@/modules/appointments/components/StatusTimelineEditDialogBase.vue';

export default {
  name: 'StatusTimelineEditDialog',
  extends: StatusTimelineEditDialogBase
};
</script>
