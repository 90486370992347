<template>
  <v-form
    ref="form"
    :class="{ disabled: readOnly, 'px-5': readOnly }"
    v-if="org && org.settings"
    id="org-settings-form"
    @keyup.native.enter="submit">
    <v-row>
      <v-col lg="10" md="12">
        <div class="mb-10">
          <h3>Overview</h3>
          <p>
            <small>Details and identification of your Organization.</small>
          </p>
          <div class="v-item-group theme--light v-expansion-panels">
            <div class="v-expansion-panel" :class="{ disabled: readOnly }">
              <div class="v-expansion-panel-header pa-2">
                <strong class="col-2">Org Name:</strong>{{ org.name }}
                <span class="v-expansion-panel-header__icon">
                  <audit-log-entity-icon-button
                    :item="org"
                    v-if="!readOnly"
                    timezone="UTC"
                    :entity-type="novaCore.DatabaseEntities.Org">
                  </audit-log-entity-icon-button>
                  <icon-tooltip-button
                    v-if="!readOnly"
                    tooltip="Edit Org Name"
                    size="large"
                    @click="showOrgDialog = true"
                    :disabled="readOnly"
                    icon="mdi-pencil">
                  </icon-tooltip-button>
                </span>
              </div>
            </div>
            <div class="v-expansion-panel" :class="{ disabled: readOnly }">
              <div class="v-expansion-panel-header pa-2">
                <strong class="col-2">
                  Org ID
                  <help-icon-tooltip> This Org's Unique ID</help-icon-tooltip>
                  : </strong
                >{{ org.id }}
                <span class="v-expansion-panel-header__icon">
                  <v-icon size="large" class="mr-2" color="secondary" @click="copyOrgId"
                    >mdi-content-copy</v-icon
                  >
                </span>
              </div>
            </div>
          </div>
          <edit-org-dialog external-activator :show-dialog="showOrgDialog" @close="updateOrgName">
          </edit-org-dialog>
        </div>
        <div class="mb-10">
          <h3>Settings</h3>
          <p>
            <small>Changes will be applied to all Warehouses across your Organization.</small>
          </p>
          <settings-expansion-panels
            :read-only="readOnly"
            :entity-key="novaCore.DatabaseEntities.Org"
            :entity-settings="this.org.settings"
            @update-settings="updateSettings">
            <template v-slot:prepended-panel>
              <v-expansion-panel>
                <v-expansion-panel-header :class="{ disabled: readOnly }" ripple>
                  Appointment Tags</v-expansion-panel-header
                >
                <v-divider></v-divider>
                <v-expansion-panel-content :class="{ disabled: readOnly }">
                  <custom-tags-form :read-only="readOnly"></custom-tags-form>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </settings-expansion-panels>
        </div>
        <action-group
          class="ml-2 mb-6"
          confirm-icon="upload"
          confirm-label="Save Org Settings"
          :disable-confirm="$isOrgReadOnly"
          :confirm-button-tooltip="$isOrgReadOnly ? novaCore.getRoleActionError() : ''"
          :hide-cancel="true"
          @confirm="submit">
        </action-group>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      org: null,
      showOrgDialog: false
    };
  },
  methods: {
    updateOrgName() {
      this.showOrgDialog = false;
      this.org.name = this.$org.name;
    },
    copyOrgId() {
      if (this.org?.id) {
        navigator.clipboard.writeText(this.org.id);
        this.notify('Copied to Clipboard!');
      }
    },
    /**
     * Submit Form
     * @public
     */
    async submit() {
      let settings = {};

      // Remove property from settings if no value
      if (this.org?.settings) {
        settings = { ...this.org.settings };
        Object.entries(settings).map(([key, value]) => {
          if (!value && value !== 0 && value !== false) {
            delete settings[key];
          } else if (key === 'muteAppointmentNotifications') {
            // Invert because we are displaying as "Notifications ENABLED" instead of "DISABLED"
            settings[key] = !settings[key];
          }
        });
      }

      // Remove externalBillingId from the request (if it exists), since Neutron does not
      // allow it to be set on UPDATE:
      if (this.org?.externalBillingId) {
        delete this.org.externalBillingId;
      }

      await this.$store.dispatch('Orgs/updateOrg', {
        ...this.org,
        settings
      });

      this.mixpanel.track(this.mixpanel.events.MODULE.ORG.SETTINGS, {
        'Org Name': this.org.name,
        'Org ID': this.org.id
      });
    },
    // Sets default values on form fields, unless the value type is a string
    setDefaultValuesOnForm() {
      this.novaCore.getDefaultOrgSettings(this.org.settings);

      // Invert because we are displaying as "Notifications ENABLED" instead of "DISABLED"
      if (typeof this.org.settings.muteAppointmentNotifications === 'undefined') {
        this.org.settings.muteAppointmentNotifications =
          !this.org.settings.muteAppointmentNotifications;
      }
    },
    updateSettings(settings) {
      this.org.settings = settings;
    }
  },
  async mounted() {
    if (this.$me.orgId) {
      this.org = await this.$store.dispatch('Orgs/getOrg', this.$me.orgId);
      this.org = this.novaCore.deepClone(this.org);

      this.org.settings = this.org.settings || {};

      if (typeof this.org.settings.muteAppointmentNotifications === 'boolean') {
        // Invert because we are displaying as "Notifications ENABLED" instead of "DISABLED"
        this.org.settings.muteAppointmentNotifications =
          !this.org.settings.muteAppointmentNotifications;
      }

      this.setDefaultValuesOnForm();
    }
  }
};
</script>

<style lang="scss">
#org-settings-form {
  .v-text-field__details {
    padding-left: 0 !important;
  }
}
</style>
