// initial state
const state = {
  docks: [],
  pagination: {},
  filters: {}
};

// getters
const getters = {
  docksByWarehouseId: state => warehouseId => {
    return state.docks.filter(dock => {
      return dock.warehouseId === warehouseId;
    });
  }
};

// actions
const actions = {
  async getDocks({ commit, state }, filters = null) {
    let response = await this.$app.services.dock.getDocks(filters || state.filters, {
      includeMetaData: true
    });

    if (response?.data) {
      commit('setApiResponseData', response.data);
      commit('setFilters', filters || state.filters);
    }

    return state.docks ?? [];
  },
  async createDock({ dispatch }, dock) {
    const response = await this.$app.services.dock.createDock(dock, { includeMetaData: true });

    if (response.status < 400) {
      dispatch('getDocks');
    }

    return response;
  },
  async updateDock({ dispatch }, dock) {
    // API requires that these do not exist
    delete dock.org;
    delete dock.orgId;

    const response = await this.$app.services.dock.updateDock(dock, { includeMetaData: true });

    if (response.status < 400) {
      dispatch('getDocks');
    }

    return response;
  },
  async deleteDock({ dispatch }, dock) {
    const response = await this.$app.services.dock.deleteDock(dock.id, { includeMetaData: true });

    if (response.status < 400) {
      dispatch('getDocks');
    }
  },
  async deactivateDock({ dispatch }, dock) {
    const response = await this.$app.services.dock.deactivateDock(dock.id, {
      includeMetaData: true
    });

    if (response.status < 400) {
      dispatch('getDocks');
    }
  },
  async activateDock({ dispatch }, dock) {
    const response = await this.$app.services.dock.activateDock(dock.id, {
      includeMetaData: true
    });

    if (response.status < 400) {
      dispatch('getDocks');
    }
  },
  async getAppointmentCountForDocks({}, dockIds) {
    const resp = await axios.get('/appointment', {
      params: {
        s: {
          dockId: { $in: dockIds }
        },
        limit: 1
      }
    });

    return resp?.data?.total || null;
  }
};

// mutations
// TODO this is duplicated code, how can we abstract?
const mutations = {
  setApiResponseData(state, response) {
    state.docks = response.data;
    state.pagination = {
      page: response.page,
      pageCount: response.pageCount,
      total: response.total
    };
  },
  setDocks(state, docks) {
    state.docks = docks;
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setPagination(state, pagination) {
    state.pagination = {
      page: pagination.page,
      pageCount: pagination.pageCount,
      total: pagination.total
    };
  }
};

export default {
  namespaced: true,
  name: 'Docks',
  state,
  getters,
  actions,
  mutations
};
