import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import config from '@/config/config';
import routeHelpers from '@satellite/helpers/routes';
import NotFound from './components/elements/NotFound';
import mixpanel from 'mixpanel-browser';
import { isMobile } from '@satellite/plugins/util';
// import UiComponents from '@satellite/modules/ui_components/UiComponentsPage';

Vue.use(VueRouter);

addCatchToRouterFunc('push');
addCatchToRouterFunc('replace');

// We're adding error handling to vue router and ignoring navigation duplicated errors
// This is a Vue "warning" telling us they aren't going to rerender the components because to/from paths match
function addCatchToRouterFunc(funcName) {
  const _func = VueRouter.prototype[funcName];
  VueRouter.prototype[funcName] = function push(...args) {
    try {
      const originalFunc = _func.call(this, ...args);
      if (originalFunc instanceof Promise) {
        return originalFunc.catch(onError);
      }
      return originalFunc;
    } catch (e) {
      onError(e);
    }
  };
}

// error handler
function onError(e) {
  // avoid NavigationDuplicated
  if (e.name !== 'NavigationDuplicated') throw e;
}

// Routes are located in the individual module folders
// Import all of the module routes files.
function loadLocalRoutes() {
  const context = require.context('@/modules', true, /routes.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map(m => m.default); // get `default` export from each resolved module
}

function loadSatelliteRoutes() {
  const context = require.context('@satellite/modules', true, /routes.js$/i);
  return context
    .keys()
    .map(context) // import module
    .map(m => m.default); // get `default` export from each resolved module
}

const moduleRoutes = [...loadLocalRoutes(), ...loadSatelliteRoutes()];
let routes = [
  // Moduleless routes can go here
  {
    path: '/',
    name: 'home',
    props: true,
    meta: {
      requiresAuth: false
    },
    beforeEnter: async (to, from, next) => {
      /**
       * We needed a way to log in a user from the scheduling portal if trying to manage the appointment
       * We pass the authenticated user's token along with a nextRoute as params to the route and use wormhole
       * to log them in here
       */
      const nextRoute = to.query?.nextRoute
        ? JSON.parse(to.query?.nextRoute)
        : { name: 'appointments' };
      const token = to.query?.token;
      if (!token) {
        return next(nextRoute);
      }
      await store.dispatch('Auth/loginAs', token, 'loginPageWithTokenParam');
      next(nextRoute);
    }
  },
  {
    path: '/internal',
    name: 'internalHome',
    redirect: 'users',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth/saml/:accessToken',
    name: 'authSaml',
    beforeEnter: async (to, from, next) => {
      const accessToken = to.params.accessToken;
      await store.dispatch('Auth/loginSaml', accessToken);
      next({
        name: 'home',
        params: {}
      });
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/wormhole/login-as/:accessToken',
    name: 'wormhole.loginAs',
    beforeEnter: async (to, from, next) => {
      const accessToken = to.params.accessToken;
      await store.dispatch('Auth/loginAs', accessToken);
      const routeData = {
        name: to.query.nextRoute ?? 'home',
        params: {}
      };
      if (Object.keys(to.query)?.length > 1) {
        delete to.query.name;
        Object.keys(to.query).forEach(key => {
          routeData.params[key] = to.query[key];
        });
      }
      next(routeData);
    }
  },
  /**
   * USED FOR PLAYWRIGHT TEST PLAYGROUND
   * Uncomment this route to allow for ui-component testing via /ui-components url.
   */
  // {
  //   path: '/ui-components',
  //   component: UiComponents,
  //   meta: {
  //     title: 'UI Components',
  //     requiresAuth: false,
  //     public: true,
  //     bypassOrgCheck: true
  //   }
  // },
  {
    path: '/404',
    component: NotFound,
    meta: {
      title: 'Page Not Found 404',
      requiresAuth: false,
      public: true,
      bypassOrgCheck: true
    }
  },
  {
    path: '*',

    redirect: '/404'
  }
];

routes = routeHelpers.registerRoutes(routes, moduleRoutes);

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

export function getComponentForDevice(mainComponent, mobileComponent) {
  if (isMobile()) {
    return mobileComponent ?? mainComponent;
  }

  return mainComponent;
}

router.beforeEach(async (to, from, next) => {
  if (to.name === 'appointmentDetails') {
    window.location.href = `${config.luna_url}/appointments/${to.params.appointmentId}`;
  }
  // Check if the from route is dirty (has unsaved data), if it is then ask user if they want to proceed
  if (await routeHelpers.checkDirty(store, from)) {
    return;
  }

  const authRequired = routeHelpers.isAuthRequired(to);

  routeHelpers.setPageTitle(store, to, config.application_name);

  if (!authRequired) {
    routeHelpers.setAppLoadedTrue(store);
    next();
    return;
  }

  if (await routeHelpers.isUserAuthorized(store)) {
    routeHelpers.setAppLoadedTrue(store);
    next();
  } else {
    if (config.mixpanel_token) {
      mixpanel.reset();
    }

    routeHelpers.setAppLoadedTrue(store);
    next({ name: 'login', query: { redirectPath: window.location.pathname } });
  }
});

router.beforeResolve(async (to, from, next) => {
  if (routeHelpers.isDisabledForUser(store, to)) {
    next({ name: routeHelpers.getHomepageNameForUser(store) });
    store.$app.notify(`Your user doesn't have access to the ${to.meta.title} page.`, 'error');
  }

  next();
});

export default router;
