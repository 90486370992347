import { render, staticRenderFns } from "./CarrierListV2.vue?vue&type=template&id=20cc1234&scoped=true"
import script from "./CarrierListV2.vue?vue&type=script&lang=js"
export * from "./CarrierListV2.vue?vue&type=script&lang=js"
import style0 from "./CarrierListV2.vue?vue&type=style&index=0&id=20cc1234&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20cc1234",
  null
  
)

export default component.exports