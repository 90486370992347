var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-actions',{staticClass:"full-width",class:{ 'd-block': _vm.stackActions }},[_vm._l((_vm.buttons),function(button){return [(button === 'extra')?[_c('div',{key:`${button}-button`,class:{
          inline: _vm.inlineActionsMobile,
          'full-width': _vm.stackActions,
          'mt-2': _vm.stackActions,
          'ml-0': _vm.stackActions
        }},[_vm._t("additional-actions")],2),(_vm.isSpacerVisible)?_c('v-spacer',{key:`${button}-spacer`}):_vm._e()]:(button === 'cancel')?[(!_vm.hideCancel)?_c('outline-button',{key:`${button}-button`,class:{
          inline: _vm.inlineActionsMobile,
          'full-width': _vm.stackActions,
          'mt-2': _vm.stackActions,
          'ml-0': _vm.stackActions
        },attrs:{"color":_vm.cancelColor,"before-icon":_vm.cancelIcon},on:{"click":function($event){return _vm.$emit('cancel', $event)}}},[_vm._v(" "+_vm._s(_vm.cancelLabel)+" ")]):_vm._e()]:(button === 'confirm')?[(_vm.confirmButtonTooltip && !_vm.hideConfirm)?_c('v-tooltip',{key:`${button}-tooltip`,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('primary-button',_vm._b({class:{ inline: _vm.inlineActionsMobile, 'full-width': _vm.stackActions },on:{"click":function($event){return _vm.$emit('confirm', $event)}}},'primary-button',_vm.confirmButtonProps,false),[_vm._v(" "+_vm._s(_vm.confirmLabel)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.confirmButtonTooltip))])]):(!_vm.hideConfirm)?_c('primary-button',_vm._b({key:`${button}-button`,class:{ inline: _vm.inlineActionsMobile, 'full-width': _vm.stackActions },on:{"click":function($event){return _vm.$emit('confirm', $event)}}},'primary-button',_vm.confirmButtonProps,false),[_vm._v(" "+_vm._s(_vm.confirmLabel)+" ")]):_vm._e()]:_vm._e()]}),_c('div',{class:{
      inline: _vm.inlineActionsMobile,
      'full-width': _vm.stackActions,
      'mt-2': _vm.stackActions,
      'ml-0': _vm.stackActions
    }},[_vm._t("additional-actions-after")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }