<template>
  <dialog-base
    v-bind="[$props, $attrs]"
    :key="renderKey"
    header="Clone Dock"
    :scrollable="true"
    max-width="450px"
    width="450px"
    has-dialog-actions
    loading-message="Cloning Dock..."
    @close="close"
    @keydown.native.enter="submit"
    @confirm="submit">
    <template v-slot:activator="slotProps" v-if="!externalActivator">
      <slot v-if="$scopedSlots.activator" name="activator" v-bind="slotProps"></slot>
      <primary-button
        v-else
        before-icon="content-copy"
        v-on="slotProps.on"
        v-bind="slotProps.attrs"
        large
        class="mr-2"></primary-button>
    </template>
    <template #body>
      <form-base hide-required-legend class="px-2">
        <template #form>
          <v-form ref="form">
            <span>
              Cloning
              <strong>{{ originalDock.name }}</strong>
            </span>
            <required-text-field
              validate-on-blur
              :rules="$validator.rules.required('Dock Name')"
              class="mb-0"
              type="text"
              hide-details="auto"
              v-model="newDock.name"
              @keydown.native.enter="submit"
              @keydown.native.enter.prevent
              label="New Dock Name"></required-text-field>
          </v-form>
        </template>
        <template #form-actions>
          <action-group
            @cancel="close"
            confirm-icon="content-copy"
            confirm-label="Clone Dock"
            @confirm="submit"></action-group>
        </template>
      </form-base>
    </template>
  </dialog-base>
</template>

<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';

/**
 * Button/Dialog for edit create/edit dock form
 * @displayName Edit Dock Dialog
 */
export default {
  mixins: [dialogMixin],
  props: {
    /**
     * Dock Object
     */
    originalDock: {
      type: Object,
      required: false
    },
    cloneSortOrder: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      newDock: {}
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.newDock.sortOrder = this.cloneSortOrder;
        const response = await this.$store.dispatch('Docks/createDock', this.newDock);
        if (response?.status === 201) {
          this.newDock = {};
          this.$emit('saved');
          this.close();
        }
      } else {
        this.notify('New Dock name is required!', 'error');
      }
    }
  },
  mounted() {
    const forbiddenFields = ['id', 'capacityChildren', 'capacityNumber', 'capacityParentId'];
    this.newDock = JSON.parse(JSON.stringify(this.originalDock));
    forbiddenFields.forEach(field => {
      delete this.newDock[field];
    });
    this.newDock.name = '';
  }
};
</script>
