import _ from 'lodash';
import { computed } from 'vue';

import { useStore } from '@/composables';

export default function useCarriers() {
  const store = useStore();
  const meRef = computed(() => _.get(store, 'state.Auth.me'));
  // NOTE(2024-07-16): @martini97: restriced users are users who have their access restricted
  // to certain warehouses
  const isWarehouseRestricted = computed(() => meRef.value?.warehouseAccessList !== null);
  return { meRef, isWarehouseRestricted };
}
