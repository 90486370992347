<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <v-dialog v-model="modal" width="550" @keydown.escape="closeModal" @input="handleDialogInput">
    <template #activator="{ on, attrs }">
      <v-btn icon v-on="on" v-bind="attrs" data-testid="carrier-contact-manage-dialog-activator">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-icon v-on="on" v-bind="attrs" class="is-favorite" v-if="carrierContact.isFavorite">
              mdi-star
            </v-icon>
            <v-icon v-on="on" v-bind="attrs" v-else>mdi-star-plus-outline</v-icon>
          </template>
          <div data-testid="carrier-contact-manage-tooltip">
            <span v-if="carrierContact.isFavorite">Manage favorite</span>
            <span v-else>Favorite this contact</span>
          </div>
        </v-tooltip>
      </v-btn>
    </template>
    <v-card class="dialog-box">
      <v-card-title>
        <span class="headline d-flex">Manage Favorite Contact</span>
        <v-spacer></v-spacer>
        <v-btn class="pa-3" icon light @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="dialog-body">
        <carrier-contact-card
          :carrier="carrierContact.user"
          :company="carrierContact.company"
          :is-favorite="carrierContact.isFavorite" />
        <div class="favorite-warehouses">
          <label for="carrier-contact-warehouses" :class="{ 'text--disabled': isOrgFavorite }">
            This carrier contact is a favorite for the following warehouses:
          </label>
          <v-autocomplete
            flat
            solo
            dense
            no-filter
            hide-details
            return-object
            id="carrier-contact-warehouses"
            class="warehouse-autocomplete"
            data-testid="carrier-contact-warehouses"
            placeholder="Select warehouses"
            item-value="id"
            item-text="name"
            multiple
            v-model="favoriteWarehouses"
            :disabled="isOrgFavorite"
            :items="warehouses.warehousesRef.value"
            :loading="warehouses.isLoadingRef.value"
            @update:search-input="warehouses.updateSearchInput">
            <template #prepend-item v-if="(warehouses.warehousesRef.value?.length || 0) > 0">
              <v-list-item ripple @click="toggleSelectAllWarehouses">
                <v-list-item-action>
                  <v-icon :color="allWarehousesSelected > 0 ? 'error darken-4' : ''">
                    {{ allWarehousesSelected ? 'mdi-close-box' : 'mdi-plus-box' }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ allWarehousesSelected ? 'Deselect All' : 'Select All' }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider slot="prepend-item" class="mt-2" />
            </template>
          </v-autocomplete>
          <v-tooltip bottom :disabled="!isWarehouseRestricted">
            <template #activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-checkbox
                  dense
                  hide-details
                  class="org-favorite-checkbox"
                  v-model="isOrgFavorite"
                  :disabled="isWarehouseRestricted"
                  :class="{ 'text--disabled': isWarehouseRestricted }"
                  label="Favorite this contact for every warehouse, including future ones" />
              </div>
            </template>
            <span>Your role does not allow you to perform this action</span>
          </v-tooltip>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="dialog-actions justify-space-between">
        <v-btn text class="text-none" @click="unfavorite" :disabled="!carrierContact.isFavorite">
          <v-icon class="mr-1">mdi-star-minus-outline</v-icon>
          Unfavorite from all
        </v-btn>
        <v-btn color="primary" @click="save" :disabled="!canSave">Save Favorite</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { defineComponent, ref, computed } from 'vue';
import useCarrierContacts from '@/composables/useCarrierContacts';
import useWarehouses from '@/composables/useWarehouses';
import useSelectAll from '@/composables/useSelectAll';
import useModal from '@/composables/useModal';
import useAuth from '@/composables/useAuth';
import useStore from '@/composables/useStore';
import MixpanelEvents from '@/enums/MixpanelEvents';
import useOrg from '@/composables/useOrg';
import { useNovaCore } from '@/composables';

export default defineComponent({
  name: 'CarrierContactManageDialog',
  props: {
    width: { type: Number, default: 550 },
    carrierContact: { type: Object, required: true }
  },
  setup(props) {
    const modal = useModal();
    const store = useStore();
    const novaCore = useNovaCore();
    const org = useOrg();
    const { isWarehouseRestricted } = useAuth();
    const warehouses = useWarehouses();
    const carrierContacts = useCarrierContacts();
    const favoriteWarehouses = ref(props.carrierContact?.favoriteWarehouses ?? []);
    const isOrgFavorite = ref(props.carrierContact?.isOrgFavorite || false);
    const canSave = computed(() => isOrgFavorite.value || favoriteWarehouses.value.length > 0);
    const { allSelected: allWarehousesSelected, toggleSelectAll: toggleSelectAllWarehouses } =
      useSelectAll({ items: warehouses.warehousesRef, selected: favoriteWarehouses });
    const resetValues = () => {
      favoriteWarehouses.value = props.carrierContact?.favoriteWarehouses ?? [];
      isOrgFavorite.value = props.carrierContact?.isOrgFavorite || false;
    };
    const closeModal = () => {
      resetValues();
      modal.closeModal();
    };
    const handleDialogInput = isOpen => {
      resetValues();
      if (isOpen && !warehouses.isLoadingRef.value) warehouses.fetch();
    };
    const updateFavoriteWarehouses = async favoriteWarehouseIds => {
      try {
        await carrierContacts.update(
          { id: props.carrierContact.id, favoriteWarehouseIds },
          {
            skipNotify: true,
            mixpanelData: {
              'Entry Point': 'Carrier Contacts Actions Column',
              'Carrier Contact Name': novaCore.namesToFullName(
                props.carrierContact.user.firstName,
                props.carrierContact.user.lastName
              ),
              'Carrier Contact Email': props.carrierContact.user.email,
              'Carrier Company Name': props.carrierContact.company?.name,
              'Carrier Company ID': props.carrierContact.company?.id,
              'Org ID': org.orgRef.value.id,
              'Org Name': org.orgRef.value.name
            },
            mixpanelEvent:
              MixpanelEvents.MODULE.CARRIER.FAVORITES[
                favoriteWarehouseIds?.length === 0 ? 'REMOVED' : 'ADDED'
              ]
          }
        );
        const message = favoriteWarehouseIds?.length === 0 ? 'unfavorited' : 'favorited';
        store.$app.notify(`Carrier Contact ${message}`, 'success');
      } catch (error) {
        console.error('Failed to update favorite warehouses', error);
      } finally {
        closeModal();
      }
    };
    const save = () => {
      const warehouses = isOrgFavorite.value ? null : _.map(favoriteWarehouses.value, 'id');
      updateFavoriteWarehouses(warehouses);
    };
    const unfavorite = () => updateFavoriteWarehouses([]);

    return {
      modal: modal.modal,
      closeModal,
      handleDialogInput,
      warehouses,
      isOrgFavorite,
      favoriteWarehouses,
      canSave,
      save,
      allWarehousesSelected,
      toggleSelectAllWarehouses,
      unfavorite,
      isWarehouseRestricted
    };
  }
});
</script>

<style scoped lang="scss">
.dialog-body {
  margin: 40px 0;
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.favorite-warehouses {
  width: 100%;
}

.warehouse-autocomplete {
  width: 100%;
  border-radius: 4px;
  border: 1px solid $color-line-border;
}

.dialog-actions {
  padding: 16px !important;
}

.org-favorite-checkbox.text--disabled > :deep(.v-input__control > .v-input__slot > .v-label) {
  color: $color-text-disabled !important;
}

.org-favorite-checkbox {
  margin: 32px 0 0 0 !important;

  :deep(.v-label) {
    font-weight: 400 !important;
  }
}

.is-favorite {
  color: $color-primary-60 !important;
}
</style>
