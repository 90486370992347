<template>
  <div>
    <create-appointment-dialog
      :external-activator="true"
      :show-dialog="showCreateDialog"
      @close="$emit('close', 'showCreateDialog')"
      :context="appointmentContext">
    </create-appointment-dialog>

    <appointment-details-dialog
      v-if="$selectedEvent"
      :external-activator="true"
      :show-dialog="showDetailsDialog"
      @close="$emit('close', 'showDetailsDialog')"
      :appointment="$selectedEvent">
    </appointment-details-dialog>

    <reserve-details-dialog
      v-if="$selectedEvent"
      :external-activator="true"
      :show-dialog="showReserveDialog"
      @close="$emit('close', 'showReserveDialog')"
      :appointment="$selectedEvent">
    </reserve-details-dialog>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Show the create appointment dialog
     */
    showCreateDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Show the appointment details dialog
     */
    showDetailsDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Show the reserve edit dialog
     */
    showReserveDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Context from calendar to be fed into create appointment form
     */
    appointmentContext: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    }
  }
};
</script>
