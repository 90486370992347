<script>
import dialogMixin from '@satellite/components/mixins/dialogMixin';
import appointmentMixin from '@/components/mixins/appointmentMixin';

/**
 * Dialog for create appointment form
 * @displayName Create Appointment Dialog
 */
export default {
  mixins: [dialogMixin, appointmentMixin],
  props: {
    /**
     * Appointment Entity
     */
    appointment: {
      type: Object,
      required: true
    }
  },
  computed: {
    appointmentWarehouse() {
      return this.appointment?.dock?.warehouse || {};
    },
    canUserAccessWarehouse() {
      return this.novaCore.canUserAccessWarehouse(
        this.$me,
        this.appointmentWarehouse?.id,
        this.appointmentWarehouse?.orgId
      );
    },
    appointmentActions() {
      return {
        share: {
          icon: 'account-multiple',
          action: 'openShareDialog',
          show: this.$rolePermissions.canUpdateAppointmentEmailCCs,
          tooltip: 'Edit Subscribers',
          isShowMoreOption: true,
          disabled: !this.canUserAccessWarehouse
        },
        recur: {
          icon: 'repeat',
          action: 'openCreateRecurringSeriesDialog',
          show:
            this.$rolePermissions.canCreateAppointment &&
            !this.novaCore.isRecurringAppointment(this.appointment),
          tooltip: 'Create Recurrence',
          disabled: !this.canUserAccessWarehouse
        },
        deleteRecur: {
          icon: 'repeat-off',
          action: 'openDeleteRecurringSeriesDialog',
          textColor: 'error',
          show:
            this.$rolePermissions.canCreateAppointment &&
            this.novaCore.isRecurringAppointment(this.appointment),
          tooltip: 'Delete Recurrence',
          disabled: !this.canUserAccessWarehouse
        },
        editRecur: {},
        clone: {
          icon: 'content-copy',
          action: 'openCreateAppointmentDialogForClone',
          show: this.$rolePermissions.canCreateAppointment,
          tooltip: 'Clone',
          disabled: !this.canUserAccessWarehouse
        },
        print: {
          icon: 'printer',
          action: 'openPrintTab',
          show: true,
          tooltip: 'Print',
          isShowMoreOption: true
        },
        reschedule: {
          icon: 'calendar-edit',
          action: 'openRescheduleDialog',
          show:
            this.$rolePermissions.canUpdateAppointment &&
            this.novaCore.getReschedulableStatuses().includes(this.appointment.status),
          tooltip: 'Reschedule',
          disabled: !this.canUserAccessWarehouse
        },
        muteNotifications: {
          icon: this.notificationsEnabled ? 'bell-ring-outline' : 'bell-off-outline',
          iconColor: this.notificationsEnabled ? '' : 'red',
          action: 'muteNotifications',
          show: this.$rolePermissions.canUpdateAppointment,
          tooltip: `${this.notificationsEnabled ? 'Mute' : 'Unmute'} Appointment`,
          disabled: !this.canUserAccessWarehouse || !this.notificationsEnabledOrgLevel,
          disabledMessage: !this.notificationsEnabledOrgLevel
            ? 'Notification Setting is disabled'
            : null
        },
        edit: {
          icon: 'pencil-outline',
          action: 'openEditDialog',
          show: this.$rolePermissions.canUpdateAppointment,
          tooltip: 'Edit Details',
          disabled: !this.canUserAccessWarehouse
        }
      };
    },
    notificationsEnabled() {
      if (!this.notificationsEnabledOrgLevel) {
        return false;
      }

      return !this.appointment.muteNotifications;
    }
  },
  data() {
    return {
      loading: false,
      parentAppointment: null,
      notificationsEnabledOrgLevel: null
    };
  },
  methods: {
    async fetchParentAppointment() {
      this.loading = true;
      this.loadingMessage = 'Loading Appointment';
      const appt = await this.$store
        .dispatch('Appointments/getAppointmentById', this.appointment.recurringParentId)
        .finally(() => {
          this.loading = false;
        });
      if (appt) {
        this.parentAppointment = appt;
      }
    },
    getNotificationSettingsValue() {
      const setting = this.$getSettingValue('muteAppointmentNotifications', this.$org?.settings);

      this.notificationsEnabledOrgLevel = setting !== true;
    },
    removeAppointmentIdFromRouteQuery() {
      // Only remove if the appointmentId is present in the query
      if (this.$route.query?.appointmentId) {
        // eslint-disable-next-line no-unused-vars
        const { appointmentId, ...newQuery } = this.$route.query;
        this.$router.replace({ name: this.$route.name, query: newQuery });
      }
    }
  },
  mounted() {
    this.getNotificationSettingsValue();
    this.$eventHub.$on('close-appointment-details', this.close);
  },
  destroyed() {
    this.$eventHub.$off('close-appointment-details', this.close);
  }
};
</script>
