<script>
import RescheduleAppointmentForm from '@satellite/modules/appointments/components/reschedule/RescheduleAppointmentForm';

export default {
  name: 'RescheduleAppointmentForm',
  extends: RescheduleAppointmentForm,
  data() {
    return {
      allowDockSelect: true
    };
  }
};
</script>
