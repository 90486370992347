<!--
  -- this component is using the new Vue Composition API, this is a step towards our goal
  -- of migrating helios into Vue3. To learn more about it, check:
  -- https://vuejs.org/api/composition-api-setup
  -- https://vuejs.org/guide/extras/composition-api-faq.html
-->
<template>
  <div class="d-flex flex-column carrier-list-wrapper">
    <v-card outlined class="carrier-list-header">
      <carrier-contact-search />
      <div class="carrier-list-create-wrapper">
        <label for="carrier-list-create" class="carrier-list-create-label">
          Didn't find your carrier contact?
        </label>
        <create-carrier-dialog @save="handleSave" entry-point="Carrier Contacts Page">
          <template #activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              color="primary"
              id="carrier-list-create"
              data-testid="carrier-list-create"
              class="carrier-list-create">
              <v-icon left size="16">mdi-plus</v-icon>
              CREATE CONTACT
            </v-btn>
          </template>
        </create-carrier-dialog>
      </div>
    </v-card>
    <carrier-contact-data-table />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import useCarriers from '@/composables/useCarriers';
import useCarrierContacts from '@/composables/useCarrierContacts';

export default defineComponent({
  name: 'CarrierListV2',
  setup() {
    const carriers = useCarriers();
    const carrierContacts = useCarrierContacts();

    const handleSave = async () => {
      await Promise.all([carriers.fetch(), carrierContacts.fetch()]);
    };

    return { carriers, handleSave };
  }
});
</script>

<style scoped lang="scss">
// TODO(2024-06-19): the styling breaks if we remove these imports,
// we need to investigate why they are not being imported by default.
@import '~vuetify/src/styles/styles.sass';
@import '@satellite/styles/main';
@import '@/styles/partials/variables';

.carrier-list-header {
  border-radius: 8px;
  border: 1px solid $color-line-border;
  display: flex;
  padding: 16px;
}

.carrier-list-create-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 16px;
  justify-content: flex-end;
}

.carrier-list-create-label {
  color: #212121;
  font-size: 14px;
  font-weight: 600;
}

.carrier-list-wrapper {
  gap: 16px;
}
</style>
