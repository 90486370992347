<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row align="center" class="search-field">
          <v-col md="6">
            <text-field
              class="mb-5"
              v-model="filters.searchStr"
              append-icon="mdi-magnify"
              label="Search Carrier Contacts"
              hint="Search Carrier Contacts"
              single-line></text-field>
            <v-tooltip bottom v-if="!novaCore.isInternalUser($me)">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-switch
                    v-model="favoritesOnly"
                    inset
                    :disabled="!doesOrgHaveFavorites"
                    label="Favorites Only"
                    data-testid="carrier-contacts-favorite-only-button"></v-switch>
                </div>
              </template>
              <span>
                {{
                  doesOrgHaveFavorites
                    ? 'Show only favorite carrier contacts'
                    : 'Your org has no favorite carrier contacts'
                }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <create-carrier-dialog
          class="action-button"
          @save="getData"
          entry-point="Carrier Contacts Page"></create-carrier-dialog>
      </v-card-title>
      <v-data-table
        data-testid="carrier-contacts-data-table"
        :headers="rowHeaders"
        :items="carriers"
        :loading="loading"
        :server-items-length="$carrierPagination.total"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="footerProps"
        :options.sync="options">
        <template v-slot:item.fullName="{ item }">
          {{ `${item.firstName} ${item.lastName}` }}
        </template>

        <template v-slot:item.email="{ item }">
          <copy-content :content="item.email" label="Click to copy carrier email">
            {{ item.email }}
          </copy-content>
        </template>

        <template #item.orgCarrierSettings="{ item }">
          <carrier-email-c-cs-column :carrier="item" />
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="row-actions">
            <carrier-subscribers-dialog
              :carrier="item"
              @save="getData"
              v-if="!novaCore.isInternalUser($me) && $rolePermissions.canCreateCarrier">
              <template v-slot:activator="slotProps">
                <v-icon
                  size="large"
                  class="mr-2"
                  color="secondary"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on">
                  mdi-account-multiple-plus
                </v-icon>
              </template>
            </carrier-subscribers-dialog>

            <favorite-button
              :carrier="item"
              :org="$org"
              @toggle-favorite="toggleFavoriteCarrier"></favorite-button>

            <edit-carrier-dialog
              :carrier="item"
              entry-point="Carrier Contacts Page"
              @save="getData"
              v-if="$rolePermissions.canUpdateCarrier">
              <template v-slot:activator="slotProps">
                <v-icon
                  size="large"
                  class="mr-2"
                  color="secondary"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on">
                  mdi-pencil
                </v-icon>
              </template>
            </edit-carrier-dialog>

            <create-appointment-dialog
              :context="{ selectedCarrier: item }"
              v-if="$rolePermissions.canCreateCarrier">
              <template v-slot:activator="slotProps">
                <icon-tooltip-button
                  tooltip="Schedule an appointment with this carrier"
                  size="large"
                  v-bind="slotProps.attrs"
                  v-on="slotProps.on"
                  color="primary"
                  icon="calendar"></icon-tooltip-button>
              </template>
            </create-appointment-dialog>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import dataListMixin from '@satellite/components/mixins/dataListMixin';
import carrierFavoritesMixin from '@/components/mixins/carrierFavoritesMixin';

/**
 * Carrier List V1
 * @displayName Carrier List V1
 */
export default {
  mixins: [dataListMixin, carrierFavoritesMixin],
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Username / Email',
          value: 'email',
          searchable: true
        },
        {
          text: 'Name',
          value: 'fullName',
          searchable: true,
          sortable: false
        },
        {
          text: 'Company',
          value: 'company.name',
          searchable: true,
          sortable: false
        },
        {
          text: 'SCAC',
          value: 'company.scac',
          searchable: false,
          sortable: false
        },
        {
          text: 'Email subscribers',
          value: 'orgCarrierSettings',
          searchable: false,
          sortable: false
        },
        {
          text: 'Phone Number',
          value: 'phone',
          searchable: true,
          sortable: false
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ],
      sortBy: ['email'],
      searchFields: ['firstName', 'lastName', 'email', 'company.name', 'phone'],
      carriers: [],
      filters: {
        searchStr: null
      },
      shouldFetchData: true
    };
  },
  methods: {
    /**
     * Get Carrier Data
     * @public
     */
    async getData() {
      try {
        this.loading = true;
        this.carriers = await this.$store.dispatch('Carriers/getCarriers', this.getQuery());
      } finally {
        this.loading = false;
      }
    },
    /**
     * Carrier query for Neutron
     * @returns {{[p: string]: *}}
     */
    getQuery() {
      // Uses base query, then additionally filters to favorites only, if selected
      return {
        ...this.getQueryBase(),
        onlyIncludeFavorites: this.favoritesOnly && this.doesOrgHaveFavorites
      };
    }
  },
  watch: {
    async favoritesOnly() {
      await this.getData();
    }
  }
};
</script>
