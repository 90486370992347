import BetaSignupPage from './BetaSignupPage';

export default [
  {
    path: '/beta',
    name: 'beta',
    component: BetaSignupPage,
    meta: {
      title: 'Beta Signup',
      bypassOrgCheck: true,
      requiresAuth: false,
      hideSidebar: true,
      customHeader: true,
      fullScreen: true
    }
  }
];
