import gridMixin from '@/modules/calendar/mixins/gridMixin';
import appointmentMixin from '@/components/mixins/appointmentMixin';
import renderMixin from '@satellite/components/mixins/renderMixin';
import { AppointmentStartTimeMinutes } from '@satellite/../nova/core';

/**
 * Mixin used in calendar
 * @mixin
 * @displayName Calendar Mixin
 */

export default {
  mixins: [appointmentMixin, renderMixin, gridMixin],
  props: {
    /**
     * Calendar events to display in calendar
     */
    events: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * Selected docks to display
     */
    docks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  computed: {
    weekdaysToShow() {
      let weekdays = [0, 1, 2, 3, 4, 5, 6];
      if (!this.$shouldShowWeekends) {
        weekdays = weekdays.slice(1, 6);
      }
      return weekdays;
    },
    selectedDayOfWeek() {
      return momentjs(this.$selectedDate)
        .format(this.novaCore.DateTimeFormats.FullWeekday)
        .toLowerCase();
    },
    timezone() {
      return this.$selectedWarehouse?.timezone;
    },
    dateLabel() {
      return this.cal ? this.cal.title : '';
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      if (this.cal && this.$selectedWarehouse?.id) {
        let currentWarehouseTime = this.$timeInWarehouseTimezone(momentjs().format());
        let dayTimeObject = this.cal.parseTimestamp(currentWarehouseTime);
        let lastIntervalTime = this.novaCore
          .clockToMoment('0:00')
          .add(this.intervals.last * this.$intervalMinutes, 'minutes');
        let firstIntervalTime = this.novaCore
          .clockToMoment('0:00')
          .add(this.intervals.first * this.$intervalMinutes, 'minutes');
        return momentjs(currentWarehouseTime).isSameOrBefore(lastIntervalTime) &&
          momentjs(currentWarehouseTime).isSameOrAfter(firstIntervalTime)
          ? this.cal.timeToY(dayTimeObject) + 'px'
          : '-10px';
      } else {
        return '-10px';
      }
    },
    selectedWarehouseId() {
      return this.$store.getters['Calendar/selectedWarehouseId'];
    },
    selectedDate: {
      get() {
        return this.$selectedDate;
      },
      set(date) {
        return this.$store.dispatch('Calendar/setSelectedDate', {
          value: date,
          getEvents: this.ready
        });
      }
    },
    firstIntervalClock() {
      return this.novaCore.minutesSinceMidnightToClock(
        this.$intervalMinutes * this.intervals.first
      );
    },
    // TODO: Not a good method name - should rename at some point for clarity
    intervals() {
      let startTime = null;
      let endTime = null;
      let intervals = { first: 0, last: 0 };
      if (
        this.$selectedWarehouse?.schedule &&
        Object.keys(this.$selectedWarehouse.schedule).length > 1
      ) {
        let schedule = this.novaCore.deepClone(this.$selectedWarehouse.schedule);
        delete schedule.version;
        delete schedule.closedIntervals;
        for (const day in schedule) {
          schedule[day].map(interval => {
            const intervalStart = this.novaCore.clockToMoment(interval.start);
            const intervalEnd = this.novaCore.clockToMoment(interval.end).subtract(1, 'minutes');
            if (!startTime) {
              startTime = intervalStart;
            }
            if (!endTime) {
              endTime = intervalEnd;
            }
            if (intervalStart.isBefore(startTime)) {
              startTime = intervalStart;
            }
            if (intervalEnd.isAfter(endTime)) {
              endTime = intervalEnd;
            }
          });
        }
      } else {
        startTime = this.novaCore.clockToMoment('0:00');
        endTime = this.novaCore.clockToMoment('23:59');
      }

      let midnight = this.novaCore.clockToMoment('0:00');

      let timeIterator = midnight.clone();
      while (timeIterator.isBefore(startTime)) {
        intervals.first++;
        timeIterator.add(this.$intervalMinutes, 'minutes');
      }

      timeIterator = midnight.clone();
      while (timeIterator.isBefore(endTime)) {
        intervals.last++;
        timeIterator.add(this.$intervalMinutes, 'minutes');
      }
      return intervals;
    },
    intervalsToShow() {
      return this.intervals.last - this.intervals.first;
    },
    appointmentIntervalsByDock() {
      let appointmentIntervalsByDock = {};
      if (this.dragEvent || this.createEvent) {
        const eventId = this.dragEvent?.id ?? this.createEvent?.id;
        this.events.forEach(event => {
          const key = !this.shouldShowCapacityDockOpts[event.parentDockId]
            ? event.parentDockId ?? event.dockId
            : event.dockId;
          if (appointmentIntervalsByDock[key] === undefined) {
            appointmentIntervalsByDock[key] = [];
          }
          const eventStart = momentjs.tz(event.start, this.$selectedWarehouse.timezone);
          const eventEnd = momentjs
            .tz(event.end, this.$selectedWarehouse.timezone)
            .subtract(1, 'minute');
          if (eventId !== event.id && event.status !== this.novaCore.AppointmentStatus.Cancelled) {
            appointmentIntervalsByDock[key].push({
              start: eventStart.toDate(),
              end: eventEnd.toDate()
            });
          }
        });
      }

      return appointmentIntervalsByDock;
    },
    useNewGridTilesSetting() {
      return this.$getSettingValue('useNewGridTiles', this.$selectedWarehouse?.settings || null);
    }
  },
  data() {
    return {
      ready: false,
      displayEventTooltip: false,
      weekdays: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
      showCreateDialog: false,
      showReserveDialog: false,
      appointmentContext: {},
      dragEvent: null,
      dragStart: null,
      isDrag: false,
      dragDelayMs: 250,
      dragTimeout: null,
      startTimeout: null,
      originalEvent: null,
      isConfirming: false,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      isExtending: false,
      currentTimeInterval: null,
      processingSchedule: false,
      mounted: false,
      eventContextMenuId: null,
      eventContextMenuEvent: null,
      shouldShowCapacityDockOpts: {},
      shouldShowLoadTypeReselectDialog: false,
      eventToUpdate: null,
      eventWarehouse: null,
      eventDock: null
    };
  },
  methods: {
    getToFromDates(event) {
      const tz = null;
      const fromDate = this.novaCore.getFormattedTime(
        this.originalEvent.start,
        this.novaCore.DateTimeFormats.ShortDayShortMonthFullYear
      );
      const fromStart = this.novaCore.formatDateTimeWithMilitarySupport(
        this.originalEvent.start,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
      const fromEnd = this.novaCore.formatDateTimeWithMilitarySupport(
        this.originalEvent.end,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
      const toDate = this.novaCore.getFormattedTime(
        event.start,
        this.novaCore.DateTimeFormats.ShortDayShortMonthFullYear
      );
      const toStart = this.novaCore.formatDateTimeWithMilitarySupport(
        event.start,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );
      const toEnd = this.novaCore.formatDateTimeWithMilitarySupport(
        event.end,
        tz,
        this.novaCore.LuxonDateTimeFormats.Extended12HrTimeAMPM,
        this.$isMilitaryTimeEnabled(this.$org),
        this.novaCore.LuxonDateTimeFormats.Extended24HrTime
      );

      return { fromDate, fromStart, fromEnd, toDate, toStart, toEnd };
    },
    buildConfirmationHTML({ to, from }) {
      return `<div>
                <span class="d-block mb-4">You are about to Reschedule this appointment:</span>
                <div class="d-flex">
                <div class="flex-grow-1">
                <div class="font-weight-bold mb-1">FROM:</div>
                <div class="d-flex flex-column">
                <span>${from.date}</span>
                <span>${from.start} - ${from.end}</span>
                <span class="${from.dock ?? 'd-none'}">${from.dock}</span>
                </div>
                </div>
                <div class="flex-grow-1">
                <div class="font-weight-bold mb-1">TO:</div>
                <div class="d-flex flex-column">
                <span>${to.date}</span>
                <span>${to.start} - ${to.end}</span>
                <span class="${to.dock ?? 'd-none'}">${to.dock}</span>
                </div>
                </div>
                </div>
             </div>`;
    },
    isTooltipDisabled(event) {
      return Boolean(this.dragEvent?.id) || event.isReserve;
    },
    doesDockSupportLoadType(dockId, loadTypeId) {
      return this.$dockById(dockId).loadTypeIds.includes(loadTypeId);
    },
    clearDragDropTimeouts() {
      clearTimeout(this.dragTimeout);
      clearTimeout(this.startTimeout);
    },
    /**
     * Get color of event
     * @public
     * @param {object} event
     * @returns {string} event color
     */
    getEventClasses(event) {
      let classes = event.color;
      if (this.isDrag && this.dragEvent?.id === event.id) {
        classes += ' is-dragging';
      }
      return classes;
    },
    /**
     * Formats provided date using moment and provided format
     * @public
     * @param {string} date
     * @param {string} format
     * @returns {string} Formatted Date
     */
    formatDate(date, format) {
      return this.novaCore.getFormattedTime(date, format);
    },
    /**
     * Scrolls to current time in day/week calendar views
     * @public
     * @return void
     */
    scrollToTime() {
      if (this.cal) {
        const currentTimeElement = document.getElementsByClassName('v-current-time')[0];
        if (currentTimeElement) {
          currentTimeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
      }
    },
    updateTime() {
      if (this.cal) {
        this.currentTimeInterval = setInterval(() => {
          this.cal.updateTimes();
        }, 60 * 1000);
      }
    },
    /**
     * Get current time
     * @public
     * @returns {*|number}
     */
    getCurrentTime() {
      return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0;
    },
    /**
     * Toggle to day view using specific date
     * @param {string} date
     * @return void
     */
    viewDay({ date }) {
      if (!this.cal.$el.classList.contains('disabled')) {
        this.$store.commit('Calendar/setFocus', date);
        this.$store.dispatch('Calendar/setViewType', { value: 'DAY', getEvents: this.ready });
        this.$store.dispatch('Calendar/setSelectedDate', {
          value: date,
          getEvents: true
        });
      }
    },
    /**
     * TODO: Only used in Grid for now, but potential candidate
     * for Core Lib abstraction if we find it's needed elsewhere
     */
    toWarehouseTime(dayTimeObject) {
      const date = dayTimeObject.date;
      // TODO: Maybe this should use the start time minutes eventually?
      let minute = this.$intervalMinutes;
      if (minute === 15) {
        if (dayTimeObject.minute < 15) {
          minute = 0;
        } else if (dayTimeObject.minute < 30) {
          minute = 15;
        } else if (dayTimeObject.minute < 45) {
          minute = 30;
        } else {
          minute = 45;
        }
      } else if (minute === 20) {
        if (dayTimeObject.minute < 20) {
          minute = 0;
        } else if (dayTimeObject.minute < 40) {
          minute = 20;
        } else {
          minute = 40;
        }
      } else {
        if (dayTimeObject.minute < 30) {
          minute = 0;
        } else {
          minute = 30;
        }
      }
      const time = `${dayTimeObject.hour}:${minute}`;
      const timezone = this.$selectedWarehouse.timezone;
      return momentjs.tz(`${date}T${time}`, this.novaCore.DateTimeFormats.DateDashedTime, timezone);
    },
    showCreateAppointmentDialog(dayTimeObject, dock) {
      dock = dock || this.$selectedDock.id;
      if (!this.$selectedWarehouse.id) {
        this.$eventHub.$emit('shake-element', 'warehouse-select');
        return;
      }
      if (!this.novaCore.canUserBookInThePast(this.$me)) {
        if (this.checkIfPast(dayTimeObject.date)) {
          this.notify('User Role cannot book appointments in the past', 'info');
          return;
        }
      }

      this.setAppointmentContext(dayTimeObject, dock);
      this.showCreateDialog = true;
    },
    setAppointmentContext(dayTimeObject, dock) {
      this.appointmentContext = {
        selectedDate: dayTimeObject.date,
        selectedDocks: [dock],
        selectedWarehouse: this.$selectedWarehouse,
        selectedTime: {
          start: this.toWarehouseTime(dayTimeObject)
        }
      };
    },
    checkIfPast(date) {
      if (!(date && this.$selectedWarehouse?.timezone)) {
        return true;
      }
      let curDate = momentjs.tz(this.$selectedWarehouse.timezone).startOf('day');
      let checkDate = momentjs(date).tz(this.$selectedWarehouse.timezone).startOf('day');
      return checkDate.isBefore(curDate);
    },
    handleDialogClose(dialogToClose) {
      this[dialogToClose] = false;
    },
    async handleEventClick(event) {
      if (!this.isDrag && !this.isExtending) {
        this.clearDragDropTimeouts();
        this.$store.commit('Calendar/setSelectedEvent', event);
        if (this.novaCore.isReserve(event)) {
          this.showReserveDialog = this.$rolePermissions.canUpdateAppointment;
        } else {
          this.showDetailsDialog = true;
          await this.$router.replace({ query: { appointmentId: event.id } });
        }
      }
    },
    attachZoomControls() {
      let zoomButtons = document.getElementById('zoom-buttons');
      zoomButtons.removeAttribute('id');
      zoomButtons.classList.remove('d-none');
      document.querySelector('.v-calendar-daily__intervals-head').append(zoomButtons);
    },
    getDockSchedule(dockId) {
      let dockSchedule = this.$selectedDocks.find(dock => {
        return dock.id === dockId;
      })?.schedule;

      if (dockSchedule && Object.keys(dockSchedule).length === 1) {
        dockSchedule = null;
      }

      return dockSchedule;
    },
    doesEventFitInSchedule(eventStartMoment, eventDuration, dockId) {
      const dockSchedule = this.getDockSchedule(dockId);
      const isDockClosed = this.isDateClosed(eventStartMoment, dockSchedule);
      const eventEndMoment = eventStartMoment.clone().add(Math.abs(eventDuration) - 1, 'minutes');
      const startIsoDayOfweek = eventStartMoment.clone().isoWeekday();
      const endIsoDayOfWeek = eventEndMoment.clone().isoWeekday();

      if (startIsoDayOfweek !== endIsoDayOfWeek || isDockClosed) {
        // Multi-day events not supported by drag and drop!
        return false;
      }

      const eventInterval = {
        start: eventStartMoment.clone().toDate(),
        end: eventEndMoment.clone().toDate()
      };

      if (!this.novaCore.isAnySubInterval(eventInterval, this.$selectedWarehouseHoops[dockId])) {
        return false;
      }

      let overlapCount = 0;
      let currentDockCapacity = 1;

      // Handle Dock Capacity
      // TODO: This doesn't prevent dragging to another child dock at the moment and should be addressed
      const parentDockId = this.$docksKeyedById[dockId]?.capacityParentId;
      if (parentDockId) {
        const overlapCounts = {
          exact: 0,
          after: 0,
          before: 0
        };
        const parentDock = this.$docksKeyedById[parentDockId];
        currentDockCapacity = parentDock.capacityChildren?.length;

        // Yuck - don't like double loop here, but not sure of any clean way to do this
        parentDock.capacityChildren.forEach(child => {
          if (this.appointmentIntervalsByDock[child.id]?.length) {
            this.appointmentIntervalsByDock[child.id].forEach(interval => {
              const exactMatch = this.novaCore.isEqual(interval, eventInterval);
              const eventStartsBetween =
                eventInterval.start > interval.start && eventInterval.start < interval.end;
              const eventEndsBetween =
                eventInterval.end < interval.end && eventInterval.end > interval.start;

              if (exactMatch) overlapCounts.exact++;
              if (eventStartsBetween) overlapCounts.after++;
              if (eventEndsBetween) overlapCounts.before++;
            });
          }

          const totalOverlappingAppointments = Object.values(overlapCounts).reduce(
            (prev, current) => prev + current
          );
          if (totalOverlappingAppointments >= currentDockCapacity) {
            overlapCount = currentDockCapacity;
          }
        });
      } else {
        overlapCount = this.novaCore.overlapCount(
          eventInterval,
          this.appointmentIntervalsByDock[dockId]
        );
      }

      return overlapCount < currentDockCapacity;
    },
    startDrag(e) {
      if (this.$rolePermissions.canUpdateAppointment) {
        this.dragTimeout = setTimeout(() => {
          if (e.event && e.timed) {
            this.originalEvent = { ...e.event };
            this.dragEvent = e.event;
            this.dragTime = null;
            this.extendOriginal = null;
          }
          setTimeout(() => {
            this.isDrag = true;
          }, 50);
        }, this.dragDelayMs);
      }
    },
    startTime(e) {
      this.startTimeout = setTimeout(() => {
        if (this.dragEvent && this.dragTime === null) {
          const mouseTime = this.toMoment(e).utc().unix();
          const eventStartTime = this.toMoment(this.dragEvent.start).utc().unix();
          this.dragTime = mouseTime - eventStartTime;
        }
      }, this.dragDelayMs);
    },
    handleDragDrop(isDockChangeable, e) {
      const eventDuration = momentjs
        .duration(this.toMoment(this.dragEvent.end).diff(this.toMoment(this.dragEvent.start)))
        .asMinutes();

      const areCustomStartTimesEnabled =
        this.$selectedWarehouse?.settings?.appointmentStartTimeMinutes ===
        AppointmentStartTimeMinutes.CUSTOM;

      let newEventStartMoment;

      if (areCustomStartTimesEnabled) {
        // Snap to allowed custom start times only
        const startNumber = this.novaCore.clockAsInt(
          momentjs
            .tz(this.roundTime(this.toMoment(e).valueOf() - this.dragTime, 1), this.timezone)
            .format('H:mm')
        );
        const startTimeNumbers = this.$selectedWarehouse?.settings?.customAppointmentStartTimes.map(
          time => {
            return this.novaCore.clockAsInt(time);
          }
        );
        const currentDate = momentjs
          .tz(this.toMoment(e).valueOf(), this.timezone)
          .format(this.novaCore.DateTimeFormats.DateSlashed);

        const nearestAllowedStart = this.novaCore.closestValueInList(startTimeNumbers, startNumber);

        newEventStartMoment = !this.isStartingStatus(this.dragEvent.status)
          ? momentjs.tz(this.dragEvent.start, this.timezone)
          : momentjs.tz(
              `${currentDate} ${this.novaCore.intToClock(nearestAllowedStart)}`,
              `${this.novaCore.DateTimeFormats.DateSlashed} ${this.novaCore.DateTimeFormats.Extended12HrTime}`,
              this.timezone
            );
      } else {
        // Snap to nearest appointment start time minute
        newEventStartMoment = !this.isStartingStatus(this.dragEvent.status)
          ? momentjs.tz(this.dragEvent.start, this.timezone)
          : momentjs.tz(this.roundTime(this.toMoment(e).valueOf() - this.dragTime), this.timezone);
      }

      const isNewTimeSameAsOldTime =
        this.toTimestamp(newEventStartMoment.clone()) === this.dragEvent.start;
      const isNewDockSameAsOldDock = this.dragEvent.category === e.category;
      const shouldUpdateEvent =
        !isNewTimeSameAsOldTime || (isDockChangeable && !isNewDockSameAsOldDock);

      const dockSupportsLoadType =
        !isDockChangeable ||
        this.doesDockSupportLoadType(e.category, this.originalEvent.loadTypeId);

      if (shouldUpdateEvent) {
        if (dockSupportsLoadType) {
          this.clearNotifications();
        }

        const canDrop = this.doesEventFitInSchedule(
          newEventStartMoment,
          eventDuration,
          isDockChangeable ? e.category : this.dragEvent.dockId
        );

        if (canDrop) {
          this.dragEvent.category = e.category;
          this.dragEvent.startMoment = newEventStartMoment;
          this.dragEvent.start = this.toTimestamp(newEventStartMoment);
          this.dragEvent.end = this.toTimestamp(
            newEventStartMoment.clone().add(eventDuration, 'minutes')
          );

          const endMoment = momentjs(this.dragEvent.end);

          // Fit event on the calendar when extend up to midnight
          if (endMoment.get('hour') === 0 && endMoment.get('minutes') === 0) {
            this.dragEvent.end = this.toTimestamp(endMoment.subtract(1, 'second'));
          }
        }
      }
    },
    handleDurationDrag(isDockChangeable, e) {
      // We always want to allow 15 minute increments when changing duration
      const roundedTime = this.roundTime(this.toMoment(e).valueOf(), this.$intervalMinutes);
      this.createEvent.startMoment = momentjs.tz(this.createStart, this.timezone);
      this.createEvent.endMoment = momentjs.tz(roundedTime, this.timezone);

      // Fit event on the calendar when extend up to midnight
      if (
        this.createEvent.endMoment.get('hour') === 0 &&
        this.createEvent.endMoment.get('minutes') === 0
      ) {
        this.createEvent.endMoment = this.createEvent.endMoment.subtract(1, 'second');
      }

      const min = this.createEvent.startMoment.clone().add(this.$intervalMinutes, 'minutes');
      let end = this.toTimestamp(this.createEvent.endMoment);
      const shouldUpdateEvent = end !== this.createEvent.start;

      if (shouldUpdateEvent) {
        if (min.isSameOrAfter(this.toMoment(e))) {
          end = this.toTimestamp(min);
          this.createEvent.endMoment = momentjs.tz(end, this.timezone);
        } else {
          let eventDuration = momentjs
            .duration(this.createEvent.endMoment.diff(this.createEvent.startMoment))
            .asMinutes();
          if (
            this.doesEventFitInSchedule(
              this.createEvent.startMoment,
              eventDuration,
              isDockChangeable ? e.category : this.createEvent.dockId
            )
          ) {
            this.createEvent.end = end;
          }
        }
      }
    },
    mouseMove(e) {
      const isDockChangeable = !!e.category;
      if (this.dragEvent && this.dragTime !== null && this.originalEvent) {
        this.handleDragDrop(isDockChangeable, e);
      } else if (this.createEvent && this.createStart !== null) {
        this.handleDurationDrag(isDockChangeable, e);
      }
    },
    resetDragDrop(timeout = 50, clearOriginalEvent = true) {
      this.dragTime = null;
      this.dragEvent = null;
      this.extendOriginal = null;
      if (clearOriginalEvent) {
        this.originalEvent = null;
      }

      setTimeout(() => {
        this.isDrag = false;
      }, timeout);
      this.clearDragDropTimeouts();
    },
    resetExtend(timeout = 50) {
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;

      setTimeout(() => {
        this.isExtending = false;
      }, timeout);
    },
    isDragEventDifferent() {
      const hasStartTimeChanged = this.dragEvent.start !== this.originalEvent.start;
      const hasDockChanged =
        this.dragEvent.category && this.dragEvent.category !== this.originalEvent.category;

      return hasStartTimeChanged || hasDockChanged;
    },
    async endDrag() {
      if (this.isExtending) {
        this.isConfirming = true;

        if (this.createEvent && this.extendOriginal === this.createEvent.end) {
          this.isConfirming = false;
          this.resetExtend();
          return;
        }

        this.confirmDurationChange();
      }
      if (this.dragEvent && this.originalEvent) {
        const isDockChangeable = !!this.dragEvent?.category;
        this.isConfirming = true;
        this.clearDragDropTimeouts();

        if (!this.isDragEventDifferent()) {
          this.isConfirming = false;
          this.cancelDrag();
          return;
        }

        if (this.isDateClosed(this.dragEvent.startMoment, this.$selectedWarehouse.schedule)) {
          this.notify('The warehouse is closed on the selected date', 'error');
          this.isConfirming = false;
          this.cancelDrag();
          return;
        }

        if (
          this.originalEvent.loadTypeId &&
          !this.doesDockSupportLoadType(
            this.dragEvent.category ?? this.dragEvent.dockId,
            this.originalEvent.loadTypeId
          )
        ) {
          await this.showLoadTypeReselect();
        } else {
          this.confirmTimeChange(isDockChangeable);
        }
      }
    },
    resetDragEvent(originalEvent) {
      if (originalEvent) {
        this.$store.commit('Calendar/insertEvent', originalEvent);
      }
    },
    cancelDrag() {
      if (this.isDrag && !this.isConfirming) {
        this.resetDragEvent(this.originalEvent);
        this.resetDragDrop();
      }
    },
    roundTime(time, minutes) {
      let roundTo =
        minutes ??
        this.$getSettingValue(
          'appointmentStartTimeMinutes',
          this.$selectedWarehouse?.settings || null
        ); // minutes
      if (!roundTo || roundTo === 0) {
        // If top of the hour or no roundTo minutes "found", we want to set to 60 to calculate the time correctly
        roundTo = 60;
      }
      const roundDownTime = roundTo * 60 * 1000;
      return Math.round(time / roundDownTime) * roundDownTime;
    },
    toMoment(tms) {
      if (typeof tms === 'object') {
        if (parseInt(tms.time.replace(':', '')) >= 2400) {
          tms.time = '00:00';
          tms.date = momentjs(tms.date)
            .add(1, 'days')
            .format(this.novaCore.DateTimeFormats.DateDashed);
        }
      }
      return typeof tms === 'string'
        ? momentjs.tz(tms, this.novaCore.DateTimeFormats.DateDashedTime, this.timezone)
        : momentjs.tz(
            `${tms.date}T${tms.time}`,
            this.novaCore.DateTimeFormats.DateDashedTime,
            this.timezone
          );
    },
    toTimestamp(moment) {
      return this.novaCore.getFormattedTime(moment, this.novaCore.DateTimeFormats.DateDashedTime);
    },
    extendBottom(event) {
      this.originalEvent = { ...event };
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
      this.isExtending = true;
    },
    confirmTimeChange(isDockChangeable) {
      const eventId = this.dragEvent.id;
      const start = this.dragEvent.startMoment.utc().format();
      const dockId = isDockChangeable ? this.dragEvent.category : this.dragEvent.dockId;
      const { fromDate, fromStart, fromEnd, toDate, toStart, toEnd } = this.getToFromDates(
        this.dragEvent
      );

      const to = {
        date: toDate,
        start: toStart,
        end: toEnd,
        dock: this.$docksKeyedById[
          isDockChangeable ? this.dragEvent.category : this.dragEvent.dockId
        ].name
      };
      const from = {
        date: fromDate,
        start: fromStart,
        end: fromEnd,
        dock: this.$docksKeyedById[this.originalEvent.dockId].name
      };

      const originalEvent = { ...this.originalEvent };
      this.resetDragDrop(0);
      this.$confirm(
        this.buildConfirmationHTML({
          to,
          from
        }),
        {
          buttonTrueText: 'Reschedule',
          title: 'Reschedule Appointment'
        }
      ).then(async confirmed => {
        if (confirmed) {
          if (this.loading) {
            return;
          }
          this.loading = true;
          await axios
            .patch(`appointment/${eventId}`, {
              start,
              dockId
            })
            .then(async response => {
              const eventId = response?.data?.data?.id;
              if (eventId) {
                this.trackDragAndDropMixpanelEvent(response.data.data);
                this.$store.commit('Calendar/removeEvent', eventId);
                this.$eventHub.$emit('appointment-updated');
              }
            })
            .catch(e => {
              this.resetDragEvent(originalEvent);
              if (!e?.response?.data?.message?.includes('full')) {
                this.notify(
                  'Unable to reschedule event, please refresh the page and try again.',
                  'error'
                );
              }
            })
            .finally(() => {
              this.isConfirming = false;
              this.loading = false;
            });
        } else {
          this.isConfirming = false;
          this.loading = false;
          this.resetDragEvent(originalEvent);
        }
      });
    },
    confirmDurationChange() {
      const eventId = this.createEvent.id;
      const start = this.novaCore.renderTimezoneInUtc(this.createEvent.start, this.timezone);
      const end = this.novaCore.renderTimezoneInUtc(this.createEvent.end, this.timezone);
      const { fromDate, fromStart, fromEnd, toDate, toStart, toEnd } = this.getToFromDates(
        this.createEvent
      );

      const to = {
        date: toDate,
        start: toStart,
        end: toEnd
      };
      const from = {
        date: fromDate,
        start: fromStart,
        end: fromEnd
      };
      const originalEvent = { ...this.originalEvent };
      this.resetExtend();
      this.$confirm(
        this.buildConfirmationHTML({
          to,
          from
        }),
        {
          buttonTrueText: 'Update',
          title: 'Update Appointment Duration'
        }
      ).then(async confirmed => {
        if (confirmed) {
          if (this.loading) {
            return;
          }
          this.loading = true;
          await axios
            .patch(`appointment/${eventId}`, {
              start,
              end
            })
            .then(async response => {
              const eventId = response?.data?.data?.id;
              if (eventId) {
                this.trackDragAndDropMixpanelEvent(response.data.data);
                this.$store.commit('Calendar/removeEvent', eventId);
                this.$eventHub.$emit('appointment-updated');
              }
            })
            .catch(() => {
              this.resetDragEvent(originalEvent);
              this.notify(
                'Unable to change event duration, please refresh the page and try again.',
                'error'
              );
            })
            .finally(() => {
              this.isConfirming = false;
              this.loading = false;
            });
        } else {
          this.isConfirming = false;
          this.loading = false;
          this.resetDragEvent(originalEvent);
        }
      });
    },
    onEscapeKeyUp(event) {
      if (event.key === 'Escape') {
        this.cancelDrag();
      }
    },
    isDateClosed(date, schedule = {}) {
      if (schedule === null) {
        return false;
      }
      const closedIntervals = schedule?.closedIntervals;
      const dateMoment = momentjs.tz(date, this.$selectedWarehouse.timezone);
      const weekDay = dateMoment.format(this.novaCore.DateTimeFormats.FullWeekday).toLowerCase();
      if (!schedule?.[weekDay] || schedule[weekDay].length === 0) {
        return true;
      }

      if (closedIntervals?.length) {
        for (const interval of closedIntervals) {
          const intervalMoment = momentjs.tz(interval.start, this.$selectedWarehouse.timezone);
          if (intervalMoment.isSame(dateMoment, 'day')) {
            return true;
          }
        }
      }

      return false;
    },
    hasDockScheduleChanged(selectedDock, dock) {
      return (
        selectedDock?.id &&
        JSON.stringify(selectedDock?.schedule) !== JSON.stringify(dock?.schedule)
      );
    },
    async handleSocketDockUpdate(dock) {
      const selectedDock = this.$selectedDocks.find(selectedDock => {
        return selectedDock.id === dock.id;
      });
      if (this.hasDockScheduleChanged(selectedDock, dock)) {
        if (this.$disabledDateTimesByWarehouse[dock.warehouseId]) {
          this.$store.commit('Calendar/setDisabledDateTimesByWarehouse', {});
          if (this.selectedWarehouseId) {
            const warehouse = await this.$store.dispatch(
              'Warehouses/getWarehouseById',
              this.selectedWarehouseId
            );
            await this.$store.dispatch('Calendar/setSelectedWarehouse', warehouse);
            const selectedDockIds = this.novaCore.pluck(this.$selectedDocks, 'id');
            const newSelectedDocks = warehouse.docks.filter(dock => {
              return selectedDockIds.includes(dock.id);
            });
            this.$store.commit('Calendar/setSelectedDocks', newSelectedDocks);
            this.setDisabledTimes();
            this.notify(`Dock ${dock.name}'s schedule has been updated`, 'info');
          }
        }
      }
      this.$nextTick(() => (this.loading = false));
    },
    async handleSocketWarehouseUpdate(warehouse) {
      if (this.selectedWarehouseId === warehouse.id) {
        if (
          JSON.stringify(this.$selectedWarehouse?.schedule) !== JSON.stringify(warehouse?.schedule)
        ) {
          this.$store.commit('Calendar/setDisabledDateTimesByWarehouse', {});
          const warehouse = await this.$store.dispatch(
            'Warehouses/getWarehouseById',
            this.selectedWarehouseId
          );
          await this.$store.dispatch('Calendar/setSelectedWarehouse', warehouse);
          this.setDisabledTimes();
          this.notify(`Warehouse ${warehouse.name}'s schedule has been updated`, 'info');
        }
      }
    },
    async handleSocketTriggerUpdate() {
      if (this.selectedWarehouseId) {
        return this.$store.dispatch('Calendar/getWarehouseTriggers', {
          warehouseId: this.selectedWarehouseId
        });
      }
    },
    cancelReserve(event, e) {
      e.stopPropagation();
      this.$store.dispatch('Appointments/deleteReserve', { id: event.id });
    },
    setEventContextMenuId(e) {
      this.clearDragDropTimeouts();
      this.eventContextMenuId = e.event.id;
      this.eventContextMenuEvent = e.nativeEvent;
    },
    handleDocumentClick() {
      this.eventContextMenuId = null;
      this.eventContextMenuEvent = null;
    },
    async getEventWarehouse(warehouseId) {
      if (warehouseId) {
        this.eventWarehouse = await this.$store.dispatch(
          'Warehouses/getWarehouseById',
          warehouseId
        );
      } else {
        this.eventWarehouse = null;
      }
    },
    async getEventDock(dockId) {
      if (dockId) {
        const dock = await this.services.dock.getDockById(dockId);
        if (dock) {
          this.eventDock = dock;
        }
      } else {
        this.eventDock = null;
      }
    },
    nevermindLoadTypeDialog() {
      this.shouldShowLoadTypeReselectDialog = false;
      this.resetDragEvent({ ...this.originalEvent });
      this.originalEvent = null;
    },
    async showLoadTypeReselect() {
      await this.getEventWarehouse(this.dragEvent?.dock?.warehouse?.id);
      await this.getEventDock(this.dragEvent?.category ?? this.dragEvent?.dockId);
      this.eventToUpdate = this.dragEvent;
      if (this.eventWarehouse && this.eventDock) {
        this.shouldShowLoadTypeReselectDialog = true;
      } else {
        this.eventWarehouse = null;
        this.eventDock = null;
      }
      this.resetDragDrop(0, false);
    },
    trackDragAndDropMixpanelEvent(appointment) {
      this.mixpanel.track(this.mixpanel.events.MODULE.APPOINTMENT.RESCHEDULED, {
        'Org Name': this.$org.name,
        'Org ID': this.$org.id,
        'Appointment ID': appointment.id,
        'Appointment Start': appointment.start,
        'Entry Point': 'Drag and Drop',
        'Appointment ETA': appointment.eta,
        'Warehouse ID': this.$selectedWarehouse.id,
        'Warehouse Name': this.$selectedWarehouse.name
      });
    },
    isStartingStatus(status) {
      return this.novaCore.isStartStatus(status);
    },
    async getSelectedWarehouseHoops() {
      if (!this.$selectedWarehouse?.id || !this.$org?.isActive) {
        return;
      }

      const startDate = momentjs
        .tz(this.selectedDate, this.$selectedWarehouse.timezone)
        .startOf('day');
      let endDate;
      switch (this.$viewType) {
        case 'DAY':
          endDate = startDate.clone().endOf('day');
          break;
        case 'WEEK':
          startDate.startOf('week');
          endDate = startDate.clone().add(1, 'week').endOf('day');
          break;
        default:
          break;
      }
      if (endDate) {
        await this.$store.dispatch('Calendar/setSelectedWarehouseHoops', {
          warehouseId: this.$selectedWarehouse.id,
          startDate,
          endDate
        });
      }
    }
  },
  async mounted() {
    this.updateTime();
    await this.handleApptIdURLQuery();
    this.scrollToTime();
    this.ready = true;
  },
  destroyed() {
    clearInterval(this.currentTimeInterval);
  },
  watch: {
    events() {
      this.$nextTick(() => {
        this.incrementRenderKey();
      });
    },
    ready(ready) {
      if (ready) {
        this.$store.commit('Calendar/setCalendar', this.cal);
      }
    },
    isDrag() {
      if (this.isDrag === false) {
        window.removeEventListener('keyup', this.onEscapeKeyUp);
      } else {
        window.addEventListener('keyup', this.onEscapeKeyUp);
      }
    },
    async $viewType(newViewType) {
      if (newViewType.toUpperCase() !== 'MONTH') {
        await this.getSelectedWarehouseHoops();
      }
      if (newViewType.toUpperCase() === 'WEEK' && !this.$shouldShowWeekends) {
        const selectedDate = momentjs(this.$selectedDate);
        if (selectedDate.isoWeekday() > 5) {
          this.$store.dispatch('Calendar/setSelectedDate', {
            value: selectedDate
              .add(1, 'weeks')
              .isoWeekday(1)
              .format(this.novaCore.DateTimeFormats.DateDashed),
            getEvents: true
          });
        }
      }
    },
    $route(newVal, oldVal) {
      this.$nextTick(async () => {
        const shouldFetchEvent =
          newVal.query.appointmentId &&
          newVal.query.appointmentId !== oldVal.query.appointmentId &&
          this.$selectedEvent?.id !== newVal.query.appointmentId;
        if (shouldFetchEvent) {
          await this.fetchAndOpenAppt(newVal.query.appointmentId);
        }
      });
    },
    async cal() {
      if (this.cal) {
        await this.updateTime();
        await this.scrollToTime();
      }
    }
  }
};
