import WarehousesPage from '@/modules/warehouses/WarehousesPage';
import WarehouseDetailsPage from '@/modules/warehouses/WarehouseDetailsPage';
import { UserRole } from '@satellite/../nova/core';

export default [
  {
    path: '/warehouses',
    name: 'warehouses',
    component: WarehousesPage,
    props: true,
    meta: {
      title: 'Warehouses',
      requiresAuth: true,
      maxRole: UserRole.OWNER
    }
  },
  {
    path: '/warehouses/:warehouseId/general',
    name: 'warehouses.details.general',
    component: WarehouseDetailsPage,
    props: true,
    meta: {
      preventTitleReset: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      title: 'Warehouse Details'
    }
  },
  {
    path: '/warehouses/:warehouseId/operating-hours',
    name: 'warehouses.details.operatingHours',
    component: WarehouseDetailsPage,
    props: true,
    meta: {
      preventTitleReset: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      title: 'Warehouse Operating Hours'
    }
  },
  {
    path: '/warehouses/:warehouseId/load-types',
    name: 'warehouses.details.loadTypes',
    component: WarehouseDetailsPage,
    props: true,
    meta: {
      preventTitleReset: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      title: 'Warehouse Load Types'
    }
  },
  {
    path: '/warehouses/:warehouseId/docks',
    name: 'warehouses.details.docks',
    component: WarehouseDetailsPage,
    props: true,
    meta: {
      preventTitleReset: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      title: 'Warehouse Docks'
    }
  },
  {
    path: '/warehouses/:warehouseId/loadtypes',
    name: 'warehouses.details.loadtypes',
    component: WarehouseDetailsPage,
    props: true,
    meta: {
      preventTitleReset: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      title: 'Warehouse Load Types'
    }
  },
  {
    path: '/warehouses/:warehouseId/forms',
    name: 'warehouses.details.forms',
    component: WarehouseDetailsPage,
    props: true,
    meta: {
      preventTitleReset: true,
      requiresAuth: true,
      maxRole: UserRole.OWNER,
      title: 'Warehouse Fields & Forms'
    }
  }
];
