<template>
  <header class="z-100 white" id="mobile-appointment-details-header">
    <section class="d-flex align-center justify-space-between pa-4">
      <div class="d-flex flex-row flex-nowrap align-center">
        <v-btn
          icon
          class="nav-arrow-btn mr-4 mobile-icon-btn"
          @click="$emit('close')"
          data-testid="back-button">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div class="d-flex flex-column">
          <strong>
            {{ formattedAppointmentDate }}
          </strong>
          <span>{{ getAppointmentInterval(appointment) }}</span>
        </div>
      </div>
      <v-btn
        v-if="canCancel && $rolePermissions.canUpdateAppointment && canUserAccessWarehouse"
        class="cancel-btn"
        elevation="0"
        @click="confirmCancel"
        :disabled="appointment.status === novaCore.AppointmentStatus.Cancelled">
        <strong>Cancel</strong>
      </v-btn>
    </section>
    <div class="status-divider" :class="[appointment.status.toLowerCase()]"></div>
    <cancel-appointment-dialog
      ref="cancelAppointment"
      :external-activator="true"
      :show-dialog="showConfirmDialog"
      :appointment="appointment"
      @close="showConfirmDialog = false"></cancel-appointment-dialog>
  </header>
</template>

<script>
import appointmentMixin from '@/components/mixins/appointmentMixin';
export default {
  mixins: [appointmentMixin],
  props: {
    appointment: {
      type: Object,
      required: true
    }
  },
  computed: {
    canUserAccessWarehouse() {
      return this.novaCore.canUserAccessWarehouse(
        this.$me,
        this.appointmentWarehouse?.id,
        this.appointmentWarehouse?.orgId
      );
    }
  }
};
</script>
